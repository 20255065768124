import { Link } from 'react-router-dom';
import SectionTitle from '../../../components/Common/SectionTitle';
// About Image Icons
import aboutImg from '../../../assets/img/about/marketing-ideas-share-research-planning-concept.jpg';

// Service Image Icons
// import leftShapeImg from '../../../assets/img/service/shape-2.png';
// import rightShapeImg from '../../../assets/img/service/shape-3.png';

const About = () => {

    return (
        <div id="rs-about" className="rs-about relative style3 pt-120 pb-120 md-pt-74 md-pb-80">
            <div className="container relative">
                <div className="row align-items-center">
                    <div className="col-lg-6 pr-56 md-pl-14 md-mb-30">
                        <SectionTitle
                            sectionClass="sec-title mb-30 zIndex"
                            subtitleClass="sub-text"
                            // subtitle="About Us"
                            titleClass="title title3 pb-30"
                             title="Innovative Brand Identity Design "
                            descClass="desc pb-30"
                            description="At Sadhvi Software Solutions, we are a premier provider of brand identity design services, dedicated to empowering businesses with unique and impactful branding."
                            secondDescClass="desc-part"
                            secondDescription="Our expert team excels in crafting distinct brand images, refining visual elements, and ensuring cohesive brand experiences. With a client-focused approach, we aim to enhance your market presence and create a lasting impact. Contact us to elevate your brand today!"
                        />
                        <div className="btn-part mt-40">
                            <Link className="readon learn-more learn-btn" to="/about">Learn More</Link>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="home8-about zIndex">
                            <img 
                                src={aboutImg}
                                alt="images" 
                            />
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="shape-part">
                <div className="left-side">
                    <img 
                        // src={leftShapeImg}
                        // alt="" 
                    /> 
                </div>
                <div className="right-side">
                    <img 
                        // src={rightShapeImg} 
                        // alt="" 
                    /> 
                </div>
            </div>
        </div>
    );
}

export default About;