import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';


// Breadcrumb Image
import bannerbg from '../../assets/img/breadcrumbs/prot1.jpg';

// helmet
import { Helmet } from 'react-helmet';

const CaseStudtyStyleOne = () => {

    return (
        <React.Fragment>
            <Helmet>
                <title>  ML, Deep Learning, NLP technologies used in Sadhvi Software Solutions
                    |Coimbatore
                    |Leading Case Study Company in India
                    |Case Study Company in Coimbatore
                    |Innovative Case Study Agency in India
                    |Trusted Case Study Agency in Coimbatore
                    |Top Case Study Services in Coimbatore
                    |Top 10 Case Study Services in Coimbatore
                    |Top 10 Case Study Services in India
                    |Top 10 Case Study Agency in Coimbatore
                    |Top 10 Case Study Agency in India


                </title>
                <meta name='description' content="Was designed to detect pneumonia and classify the ticket into the right category." />
                <meta name="keywords" content='machine learning,python,tensor-flow,keras,scikit-learn,OPEN-CV,NLTK,Pandas,
            LSTM,text preprocessing,CNN,Natural language processing,computer vision,deep learning'/>
            </Helmet>
            {/* Header */}
            <Header
                parentMenu='Portfolio'
                secondParentMenu='case-studies'
                activeMenu='/pneumonia-detection'
            />
            {/* Header */}

            {/* SiteBreadcrumb */}
            <SiteBreadcrumb
                pageTitle="Pneumonia Detection & Automatic Ticket Classification"
                pageName="Pneumonia Detection & Automatic Ticket Classification"
                breadcrumbsImg={bannerbg}
            />
            {/* SiteBreadcrumb */}
            <div className='container_casestudy'>
                <p>
                    <h3>Challenge</h3>
                    Medical detection is a complicated task, therefore, an effective approach. Our project was designed to accurately detect pneumonia and classify incoming tickets into their appropriate categories. Our model was able to detect the presence of pneumonia in images and classify the ticket into the right category, taking the hassle out of medical detection and giving the confidence needed to make the right diagnosis.<br></br>

                    <h3>Scope and Motivation</h3>

                    This project examined the use of ML techniques for medical image detection and image data security. We found that 24.3 million image data had been compromised by cyber-attacks. The project evaluated state-of-the-art ML techniques to provide effective medical image detection and data security.<br></br>

                    <h3>Solution</h3>
                    <ul>
                        <li>The solution proposed for this challenge was to develop a machine-learning model that could detect pneumonia from an image and classify the ticket into the appropriate category. </li>

                        <li>Once the dataset was collected, the next step was to preprocess the images and tickets. This included formatting the images and tickets, normalizing the data, and resizing the images. </li>

                        After preprocessing, the images and tickets were fed into the machine-learning model. The model was able to learn the patterns in the data and accurately classify the tickets into their appropriate categories. <br></br>

                        The model was then trained on a large dataset of pneumonia images and associated tickets and tested for accuracy. <br></br>

                        Finally the model was deployed and used to classify incoming tickets. The model was able to recognize patterns in the image and then categorize the ticket accurately.<br></br>
                    </ul>
                    <h3>Technologies and Tools </h3>
                    <strong>Technologies:</strong> Machine Learning, Deep Learning, Computer Vision, Natural Language Processing, CNN, Text Preprocessing, LSTM.<br></br>
                    <strong>Tools:</strong> Python, Tensor-Flow, Keras, scikit-learn, OPEN-CV, NLTK, Pandas.
                </p>

            </div>

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            {/* Footer */}
            <Footer />
            {/* Footer */}

            {/* SearchModal */}
            <SearchModal />
            {/* SearchModal */}
        </React.Fragment>

    );
}


export default CaseStudtyStyleOne;