import React from 'react';
import Header from '../../../../components/Layout/Header';
import Footer from '../../../../components/Layout/Footer';
import SearchModal from '../../../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../../../components/Common/Breadcumb';

import Service from './ServiceSection';
import WhyChooseUs from './WhyChooseUsSection';
import CTA from '../../../../components/Common/CTA';
// Breadcrumbs Image
import breadcrumbsImg from '../../../../assets/img/breadcrumbs/digitalmark/6.jpg';
import WhatsappContent from './WhatsappContent';

const whatsapp = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='service'                
                activeMenu='/whatsapp'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Whatsapp Marketing'               
                pageName='Whatsapp Marketing'
            />
            {/* breadcrumb-area-end */}

            {/* SeoContent-area-start */}
            <WhatsappContent />
            {/* SeoContent-area-end */}

            {/* Service-area-start */}
            <Service />
            {/* Service-area-end */}

            {/* WhyChooseUs-area-start */}
			<WhyChooseUs />
			{/* WhyChooseUs-area-end */}

           {/* newsletter-area-start */}
			<CTA
            ctaSectionClass="rs-cta style1 cta-bg1 pt-70 pb-70"
            ctaTitleClass="epx-title"
            ctaTitle="Supercharge Your Online Success with Professional Whatsapp marketing Services!"
            ctaButtonClass="readon learn-more"
            ctaButtonLink="/contact"
            ctaButtonText="Get In Touch"
        />
        {/* newsletter-area-end */}
         

            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default whatsapp;