import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/webdevimg/ecomercebred.jpg';
import ECommerceWebSite from './WebDevelopment-ews';


const Webdevwordpress = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='service'
                
                activeMenu='/web-development'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='E-Commerce WebSite'
                
                pageName='E-Commerce WebSite'
            />
            {/* breadcrumb-area-end */}
            <ECommerceWebSite />
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default Webdevwordpress;