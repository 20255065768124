import SectionTitle from '../../../../components/Common/SectionTitle';
import SingleServiceBox from '../../../../components/Service/SingleServiceBox';

// Service Icons
import img1 from '../../../../assets/img/technology/style5/1.png';
import img2 from '../../../../assets/img/technology/style5/2.png';
import img3 from '../../../../assets/img/technology/style5/3.png';
import img4 from '../../../../assets/img/technology/style5/4.png';
import img5 from '../../../../assets/img/technology/style5/5.png';
import img6 from '../../../../assets/img/technology/style5/6.png';
import img7 from '../../../../assets/img/technology/style5/7.png';
import img8 from '../../../../assets/img/technology/style5/8.png';
import img9 from '../../../../assets/img/technology/style5/9.png';
import img10 from '../../../../assets/img/technology/style5/10.png';
import img11 from '../../../../assets/img/technology/style5/11.png';
import img12 from '../../../../assets/img/technology/style5/12.png';
import img13 from '../../../../assets/img/technology/style5/13.png';
import img14 from '../../../../assets/img/technology/style5/14.png';
import img15 from '../../../../assets/img/technology/style5/15.png';

const Service = () => {

    return (
        <div className="rs-services style8 pt-120 pb-114 md-pt-70 md-pb-70"style={{  paddingtop: '40px !important' }}>
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text gold-color"
                    // subtitle="Work For Any Industry"
                    titleClass="title"
                    title="Boost Your Website's Visibility with SEM Services"
                />
                <div className="all-services">
                    <SingleServiceBox
                        serviceIcon={img1}
                        Title="Paid Search Advertising " 
                    />
                    <SingleServiceBox
                        serviceIcon={img2}
                        Title="Ad Campaign Creation and Management" 
                    />
                    <SingleServiceBox
                        serviceIcon={img3}
                        Title="Ad Copywriting" 
                    />
                    <SingleServiceBox
                        serviceIcon={img4}
                        Title="Bid Management" 
                    />
                    <SingleServiceBox
                        serviceIcon={img5}
                        Title="Ad Performance Tracking and Analytics" 
                    />
                    <SingleServiceBox
                        serviceIcon={img6}
                        Title="Remarketing Campaigns" 
                    />
                    <SingleServiceBox
                        serviceIcon={img7}
                        Title="Reporting and Insights" 
                    />
                    <SingleServiceBox
                        serviceIcon={img8}
                        Title="Display Advertising" 
                    />
                    <SingleServiceBox
                        serviceIcon={img9}
                        Title="Shopping Campaigns" 
                    />
                    <SingleServiceBox
                        serviceIcon={img10}
                        Title="Local Search Advertising" 
                    />
                    <SingleServiceBox
                        serviceIcon={img11}
                        Title="Mobile Advertising" 
                    />
                    <SingleServiceBox
                        serviceIcon={img12}
                        Title="Video Advertising" 
                    />
                    <SingleServiceBox
                        serviceIcon={img13}
                        Title="Social Media Advertising" 
                    />
                    <SingleServiceBox
                        serviceIcon={img14}
                        Title="Competitive Analysis" 
                    />
                    <SingleServiceBox
                        serviceIcon={img15}
                        Title="Conversion Rate Optimization" 
                    />
                </div>
            </div>
        </div>
    );
}

export default Service;