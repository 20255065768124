import React from 'react';
import Header from '../../../components/Layout/Header';
import Footer from '../../../components/Layout/Footer';
import SearchModal from '../../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';

//import Service from 'ServiceSection';
//import WhyChooseUs from 'WhyChooseUsSection';
import Service from '../DigitalMarketing/content/ServiceSection copy';
import WhyChooseUs from '../DigitalMarketing/content/WhyChooseUsSection copy';
import CTA from '../../../components/Common/CTA';
// Breadcrumbs Image
import breadcrumbsImg from '../../../assets/img/breadcrumbs/digitalmark/AnalyticsandReporting.jpg';
// import ContentMarketingContent from './ServiceSection';

import ContentMarketingContent from '../DigitalMarketing/content/ContentMarketingContent copy';

const whatsapp = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='service'                
                activeMenu='/Analytics And Report'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Analytics And Report'               
                pageName='Analytics And Report'
            />
            
            {/* breadcrumb-area-end */}

            {/* SeoContent-area-start */}
             <ContentMarketingContent/>
            {/* SeoContent-area-end */}

            {/* Service-area-start */}
            <Service />
            {/* Service-area-end */}
<br></br><br></br><br></br><br></br>
            {/* WhyChooseUs-area-start */}
			<WhyChooseUs />
			{/* WhyChooseUs-area-end */}

            {/* newsletter-area-start */}
			<CTA
                ctaSectionClass="rs-cta style1 cta-bg1 pt-70 pb-70"
                ctaTitleClass="epx-title"
                ctaTitle="Supercharge Your Online Success with Professional Analytics And Report Services!"
                ctaButtonClass="readon learn-more"
                ctaButtonLink="/contact"
                ctaButtonText="Get In Touch"
            />
            {/* newsletter-area-end */}
         
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default whatsapp;