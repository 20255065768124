import SectionTitle from '../../../components/Common/SectionTitle';
import SingleServiceThree from '../../../components/Service/SingleServiceThree';

// Service Image Icons
import serviceIcon1 from '../../../assets/img/service/styleWP/1.png';
import serviceIcon2 from '../../../assets/img/service/styleWP/2.png';
import serviceIcon3 from '../../../assets/img/service/styleWP/3.png';
import serviceIcon4 from '../../../assets/img/service/styleWP/4.png';
import serviceIcon5 from '../../../assets/img/service/styleWP/5.png';
import serviceIcon6 from '../../../assets/img/service/styleWP/6.png';

const ServiceTwo = () => {
    return (
        <div id="rs-service" className="rs-services style4 modify1 gray-bg pt-110 pb-120 md-pt-74 md-pb-40 sm-pb-70">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text"
                    // subtitle="WordPress Services"
                    titleClass="title"
                    title="We Create, Develop, and Optimize Websites for Your Success"
                />
                <div className="row">
                    <div className="col-lg-4 mb-20">
                        <SingleServiceThree
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            Title="Custom WordPress Development"
                            Text="We craft unique websites to match your style, making sure they work just as you want"
                            serviceBtn="Read More"
                        />
                    </div>
                    <div className="col-lg-4 mb-20">
                        <SingleServiceThree
                            itemClass="services-item"
                            serviceImage={serviceIcon2}
                            Title="Website Maintenance"
                            Text="We keep your site running smoothly, fixing issues and making sure it's always at its best"
                            serviceBtn="Read More"
                        />
                    </div>
                    <div className="col-lg-4 mb-20">
                        <SingleServiceThree
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            Title="SEO Optimization"
                            Text="Boost your website's visibility on search engines, bringing in more visitors and potential customers"
                            serviceBtn="Read More"
                        />
                    </div>
                    <div className="col-lg-4 md-mb-20">
                        <SingleServiceThree
                            itemClass="services-item"
                            serviceImage={serviceIcon4}
                            Title="WooCommerce Website"
                            Text="We build online stores that are easy to manage, secure, and perfect for selling your products"
                            serviceBtn="Read More"
                        />
                    </div>
                    <div className="col-lg-4">
                        <SingleServiceThree
                            itemClass="services-item"
                            serviceImage={serviceIcon5}
                            Title="Website Migration"
                            Text="Move your website hassle-free to a new home, ensuring everything stays intact and works smoothly"
                            serviceBtn="Read More"
                        />
                    </div>
                    <div className="col-lg-4">
                        <SingleServiceThree
                            itemClass="services-item"
                            serviceImage={serviceIcon6}
                            Title="WordPress Website Redesign"
                            Text="Give your website a fresh look and feel, making it more appealing and user-friendly for visitors"
                            serviceBtn="Read More"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceTwo;