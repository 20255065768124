import { Link } from 'react-router-dom';

import bgImg from '../../../../assets/img/bg/choose-bg.jpg';

const WhyChooseUs = () => {
    const bgStyle = {
        backgroundImage: `url(${bgImg})`
    }


    return (
        <div className="rs-why-choose style3 pt-120 pb-120 md-pt-74 md-pb-80" style={bgStyle}>
            <div className="container relative">
                <div className="sec">
                    <h1 className="title white-color">Why Invest in Analytics and Reporting? Harnessing the Benefits</h1>
                </div>
                <br></br>
                <div className="row align-items-center">
                    <div className="col-lg-12 md-pb-30">
                        <div className="features-content">
                            <ul className="features-list">
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>
                                        Informed Decision-Making
                                        Data-driven insights help you make better decisions that align with your business goals.</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Enhanced Performance Tracking
                                        Monitor and measure performance against KPIs to ensure objectives are met.</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i><span>
                                        Optimized Marketing Strategies
                                        Analyze marketing data to refine strategies and improve campaign effectiveness.</span>
                                </li>
                                <li><i className="fa fa-check"></i>
                                    <span>Increased Operational Efficiency
                                        Identify inefficiencies and areas for improvement to streamline operations.</span></li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Customer Insights
                                        Gain a deeper understanding of customer behavior and preferences to enhance engagement.</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i><span>
                                        Competitive Advantage
                                        Leverage data insights to stay ahead of competitors and adapt to market changes.</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Forecasting and Planning
                                        Use predictive analytics to anticipate future trends and plan accordingly.</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span> Cost Savings
                                        Optimize resource allocation and reduce costs through data-informed decisions.</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Strategic Growth
                                        Use insights to drive strategic growth and business development.</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;