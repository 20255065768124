import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SectionTitle from '../../components/Common/SectionTitle';

import CaseStyleOne from '../../components/CaseStudy/CaseStyleOne';
import image2 from '../../assets/img/project/arcadia/2.png';
import image3 from '../../assets/img/project/arcadia/3.png';
import image4 from '../../assets/img/project/arcadia/4.GIF';
import image5 from '../../assets/img/project/arcadia/5.GIF';
import image6 from '../../assets/img/project/arcadia/6.jpeg';
import image7 from '../../assets/img/project/arcadia/7.jpeg';


// Breadcrumb Image
import bannerbg from '../../assets/img/breadcrumbs/arcadia.jpg';

const ProjectTwo = () => {
    return (
        <React.Fragment>
        <Header
        parentMenu='Portfolio'
        secondParentMenu='case-studies'
        activeMenu='/arcadia-hotel'
         />

         {/* SiteBreadcrumb */}
        <SiteBreadcrumb
        pageTitle="Arcadia Hotel Supplies"
        pageName="Arcadia Hotel Supplies"
        breadcrumbsImg={bannerbg}
        />
        {/* SiteBreadcrumb */}

        <SectionTitle
                    sectionClass="sec-title2 text-center mb-80 mt-80"
                    subtitleClass="sub-text style-bg"
                    // subtitle="Portfolio"
                   
        />
        <div class="container-client">
            <h1><span class="larger-word">Our</span> <span class="unique-word">Client</span></h1>
                <p>Dive into our partnership with Arcadia Hotel Supplies, where we brought their brand partners and products to the forefront. Discover how our website design reflects the elegance and quality of their hospitality offerings.</p>
        </div>


        
        <div>
                <div className="image-container1">
                    <img
                        src={image2} 
                        alt="Project Image"
                        className="zoom-image"
                    />
                </div>
            </div>

            <div class="container-client">
            <h1><span class="larger-word">Client's</span> <span class="unique-word">Need</span></h1>
                <p>Arcadia Hotel Supplies desired an online platform that reflects the sophistication of their brand. Learn how our solution met this need by providing a visually appealing and functional website, connecting them with partners and customers.</p>
            </div>

            <div class="container-client">
            <h1><span class="larger-word">Our</span> <span class="unique-word">Solution</span></h1>
                <p>Our tailor-made solution for Arcadia Hotel Supplies involved creating a website that seamlessly integrates brand partners and showcases a diverse range of top-quality hotel supplies. The design and functionality aim to enhance the overall guest experience.</p>
            </div>


            <div class="container-client">
            <h1><span class="larger-word">Tools &</span> <span class="unique-word">Technology</span></h1>
                <p>Harnessing the capabilities of WordPress, we crafted an elegant and functional website for Arcadia Hotel Supplies. The platform seamlessly integrates brand partners and showcases top-quality hotel supplies, providing a visually appealing experience for users and fostering efficient connections.</p>
            </div>

            <div className="image-row">
                <div className="image-container custom-width">
                    <img
                        src={image4}
                        alt="Image 1"
                        className="image-website"
                    />
                </div>

                <div className="image-container custom-width">
                    <img
                        src={image5}
                        alt="Image 2"
                        className="image-website"
                    />
                </div>
            </div>

            <div className="image-container">
                    <img
                        src={image3}
                        alt="Image 2"
                        className="image-website"
                    />
            </div>

            <div className="image6-container">
                    <img
                        src={image6}
                        alt="Image 2"
                        className="image6-website"
                    />
            </div>

            <div className="image7-container">
                    <img
                        src={image7}
                        alt="Image 2"
                        className="image7-website"
                    />
            </div>



        {/* scrolltop-start */}
        <ScrollToTop />
        {/* scrolltop-end */}

        {/* Footer */}
        <Footer />
        {/* Footer */}

        {/* SearchModal */}
        <SearchModal />
        {/* SearchModal */}


    </React.Fragment>
);
}

export default ProjectTwo;