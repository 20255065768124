import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';

import ProgressBar from '../../components/Elements/Progress/ProgressBar';
import ScrollToTop from '../../components/Common/ScrollTop';
import SingleServicebridentity from '../../components/Service/SingleServicebridentity';
// Service Image
import serviceIcon1 from '../../assets/img/service/style7/1.png';
import serviceIcon2 from '../../assets/img/service/style7/2.png';
import serviceIcon3 from '../../assets/img/service/style7/3.png';

import serviceIcon01 from '../../assets/img/icon/logo-design.png';
import serviceIcon02 from '../../assets/img/icon/mobile-app.png';
import serviceIcon03 from '../../assets/img/icon/brandidentitydesign.png';
import serviceIcon04 from '../../assets/img/icon/ui-ux.png';
// import aboutImg1 from '../../assets/img/about/1.jpg';
// changes 
import aboutImg1 from '../../assets/img/newimges/ui-ux-representations-with-laptop.jpg';

// FlowChart Image
import Flowchart from '../../assets/img/flowchart/624cd884605ea2331cf5992b_7_step_web_design_process.png'
import '../../assets/scss/App.scss'

// Choose Us Image
// import whyChooseUsImg from '../../assets/img/choose/3.png';
// changes 
import whyChooseUsImg from '../../assets/img/newimges/Increasing Business.avif';

import SingleServiceTwologo from '../../src/components/Service/SingleServiceTwologo';
import SingleServiceSocialMediaDesign from '../../components/Service/SingleServiceSocialMediaDesign';
import SingleServiceuiuxdev from '../../components/Service/SingleServiceuiuxdev';
//Helmet
import { Helmet } from 'react-helmet';

const WebDevelopmentContent = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title> Web design company in india
                    | Web design company in coimbatore
                    | Web design agency in india
                    | Web design agency in coimbatore
                    | Web design services in coimbatore
                    |top 10 Web design services in coimbatore
                    |top 10 Web design services in india
                    |top 10 Web design agency in coimbatore
                    |top 10 Web design agency in India
                </title>
                <meta name='description' content="Expert web design and development services utilizing HTML, CSS, JavaScript, and the MERN stack. We create responsive, high-performance websites for desktop and mobile, with seamless social media integration and ongoing support." />
                <meta name="keywords" content='web design, web development, HTML, CSS, JavaScript, MERN stack, responsive design, mobile optimization, social media integration, React.js, Node.js, full stack development, static websites, dynamic websites, website maintenance' />
              </Helmet>
            {/* About Section Start */}
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                // subtitle="Web Design"
                                titleClass="title"
                                title="Captivate Your Audience with Stunning Web Design"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="At Sadhvi Software Solutions, we bring your ideas to life with high-quality, 
                                user-friendly websites. Our services include custom web design, content management systems,
                                 and complete e-commerce solutions. We focus on enhancing your brand's online presence and
                                 engaging your customers. Committed to excellence, we deliver outstanding customer service 
                                 and support."
                            />
                            {/* Section Title End */}

                        </div>
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg1} alt="Best Website development company in coimbatore" />
                            </div>
                        </div>
                    </div>
                    <div className="btn-part mt-46">
                        <Link to="/contact">
                            <a className="readon learn-more">Contact Us</a>
                        </Link>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    {/* How we do Start */}
                    {/* <SectionTitle
                        titleClass="title"
                        title="How We Do"
                        sectionClass="sec-title4"
                        descClass="desc desc-big"
                        description={(
                            <p>
                            <strong>Logo Design:</strong> Elevate your brand with intuitive, customizable, and seamless logo design solutions.<br /><br />

                            <strong>Social Media Design:</strong> Enhance your online presence with captivating, engaging, and customized social media designs.<br /><br />

                            <strong>Brand Identity Design:</strong> Create a stunning and cohesive brand identity with impactful designs that resonate with your audience.<br /><br />

                            <strong>Web Design UI/UX:</strong> Deliver a seamless, intuitive, and powerful web design experience with UI/UX solutions that drive conversions.<br /><br />
                        </p>
                        )}
                    /> */}

                    {/* How we do End */}

                    {/* Flow Chart Start*/}
                    {/* <div className='container_flowchart'>
                        <div className="image_flowchart">
                            <img src={Flowchart} alt="" />
                        </div>
                    </div> */}
                    {/* Flow Chart Start*/}
                    {/* <div className="rs-services style5 pt-10 md-pt-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 md-mb-30">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon1}
                                        Title="Expert People"
                                        Text="With experts, we stay ahead of the competition and better assist our clients."
                                    />
                                </div>
                                <div className="col-lg-4 md-mb-30">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon2}
                                        Title="First Growing Process"
                                        Text="
                                        With new emerging technologies, we develop strategic partnerships in a challenging manner. "
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon3}
                                        Title="Creative Ideas"
                                        Text="Our creative ideas allow us to view and solve problems more openly with innovation. "
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div id="rs-service" className="rs-services main-home gray-bg pt-110 pb-120 md-pt-70 md-pb-70">
                <div className="container">

                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text"
                        // subtitle="Services"
                        titleClass="title"
                        title="Transform Your Business With Stunning Web Design"
                        effectClass="heading-line"
                    />
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleServiceTwologo
                                itemClass="services-item"
                                serviceImage={serviceIcon01}
                                serviceURL="web-design"
                                Title="Logo Design"
                                Text="Elevate your brand with intuitive and customizable logo designs that seamlessly align with your vision."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleServiceSocialMediaDesign
                                itemClass="services-item"
                                serviceImage={serviceIcon03}
                                serviceURL="analytics-solution"
                                Title="Social Media Design"
                                Text="Enhance your online presence with engaging and customized social media designs that captivate and connect with your audience."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleServicebridentity
                                itemClass="services-item"
                                serviceImage={serviceIcon02}
                                serviceURL="/service/brand-identity"
                                Title="Brand Identity Design"
                                Text="Create a stunning and cohesive brand identity with impactful designs that truly resonate and elevate your brand."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleServiceuiuxdev
                                itemClass="services-item"
                                serviceImage={serviceIcon04}
                                serviceURL="/service/web-design/web-designs-ui-ux"
                                Title="Web Design UI/UX"
                                Text="Deliver a seamless and powerful online experience with intuitive web design UI/UX solutions that drive conversions."
                            />
                        </div>
                        {/* <div className="col-lg-6 col-md-6 offset-lg-3 offset-md-3">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon04}
                                        serviceURL="digital-marketing"
                                        Title="Strategic Digital Marketing"
                                        Text="Advertise, Optimize and Analyze it for you to reach your desired target markets and drive more sales."
                                    />
                                </div> */}
                    </div>
                </div>
            </div>


            {/* Why Choose US Section Start */}
            <div className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pr-46 md-pr-15 md-mb-50">
                            <div className="choose-img">
                                <img className="main" src={whyChooseUsImg} alt="Providing an excellent web development projects to the clients with innovative and appealing model. Startup company in coimbatore." style={{ height: '501px' }} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text choose"
                                // subtitle="Why Choose Us"
                                titleClass="title pb-20"
                                title="We Are Increasing Business Success With Technology"
                                descClass="desc"
                                description="Sadhvi Software Solutions offers high-quality website services
                                 with fast delivery, excellent customer service, and affordable prices. We have experience building websites for all types of businesses,
                                 always maintaining the highest standards."
                            />
                            {/* Section Title End */}
                            <div className="row">
                                <div className="col-lg-10">
                                    {/* Skill Bar Start */}
                                    <div className="rs-skillbar style1 modify1">
                                        <div className="rs-skillbar style1">
                                            <ProgressBar
                                                completed={90}
                                                height={7}
                                                boxbg={"#eeeeee"}
                                                figurebg={"#f00"}
                                                ProgressBox="progress-box"
                                                ProgressFigure="progress-figure"
                                                ProgressCount="Progress-count"
                                                ProgressLabel="progress-label"
                                                label="Web Design"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={98}
                                                height={7}
                                                boxbg={"#eeeeee"}
                                                figurebg={"#f00"}
                                                ProgressBox="progress-box"
                                                ProgressFigure="progress-figure"
                                                ProgressCount="Progress-count"
                                                ProgressLabel="progress-label"
                                                label="Web Development"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={90}
                                                height={7}
                                                boxbg={"#eeeeee"}
                                                figurebg={"#f00"}
                                                ProgressBox="progress-box"
                                                ProgressFigure="progress-figure"
                                                ProgressCount="Progress-count"
                                                ProgressLabel="progress-label"
                                                label="Data Science"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={95}
                                                height={7}
                                                boxbg={"#eeeeee"}
                                                figurebg={"#f00"}
                                                ProgressBox="progress-box"
                                                ProgressFigure="progress-figure"
                                                ProgressCount="Progress-count"
                                                ProgressLabel="progress-label"
                                                label="Brand Identity Design"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={95}
                                                height={7}
                                                boxbg={"#eeeeee"}
                                                figurebg={"#f00"}
                                                ProgressBox="progress-box"
                                                ProgressFigure="progress-figure"
                                                ProgressCount="Progress-count"
                                                ProgressLabel="progress-label"
                                                label="Strategic Digital Marketing"
                                                ProgressParent="main-div"
                                            />
                                        </div>
                                    </div>
                                    {/* Skill Bar End */}
                                </div>
                            </div>
                            <div className="btn-part mt-46">
                                <Link to="/contact">
                                    <a className="readon learn-more">Contact Us</a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Why Choose US Section End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}

export default WebDevelopmentContent;