import { Link } from 'react-router-dom';

import bgImg from '../../../../assets/img/bg/choose-bg.jpg';

const WhyChooseUs = () => {
    const bgStyle = {
        backgroundImage:`url(${bgImg})`
    }


    return (
        <div className="rs-why-choose style3 pt-120 pb-120 md-pt-74 md-pb-80" style={bgStyle}>
            <div className="container relative">
                <div className="sec">
                    <h1 className="title white-color">Why Should You Use SEM? Unlocking the Benefits</h1>
                </div>
                <br></br>
                <div className="row align-items-center">
                    <div className="col-lg-12 md-pb-30">
                        <div className="features-content">
                            <ul className="features-list">
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>
                                    SEM helps your website appear prominently in search engine results, increasing your chances of being noticed by potential customers</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>SEM allows you to reach specific audiences who are actively searching for products or services related to your business</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i><span>
                                    You have control over your budget and bids, ensuring that you get the most value for your investment</span>
                                </li>
                                <li><i className="fa fa-check"></i>
                                <span>With SEM, you can start driving targeted traffic to your website almost instantly, unlike other marketing methods that may take longer to yield results</span></li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>SEM provides robust analytics tools to measure and track the performance of your campaigns, allowing you to optimize and improve your strategies</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i><span>
                                    Being consistently present in search results builds brand recognition and establishes credibility in the minds of potential customers</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Utilizing SEM gives you an edge over competitors who are not actively engaging in search engine marketing</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>By targeting relevant search queries, SEM drives qualified leads to your website, resulting in increased conversion rates</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>SEM enables effective remarketing campaigns, allowing you to engage with users who have previously shown interest in your products or services</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;