import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';
import ProgressBar from '../../components/Elements/Progress/ProgressBar';
import ScrollToTop from '../../components/Common/ScrollTop';
import SingleProcess from '../../components/Common/Process';
// Service Image
import serviceIcon1 from '../../assets/img/service/style7/1.png';
import serviceIcon2 from '../../assets/img/service/style7/2.png';
import serviceIcon3 from '../../assets/img/service/style7/3.png';

import serviceIcon01 from '../../assets/img/icon/logo-design.png';
import serviceIcon02 from '../../assets/img/icon/mobile-app.png';
import serviceIcon03 from '../../assets/img/icon/brandidentitydesign.png';
import serviceIcon04 from '../../assets/img/icon/ui-ux.png';
// import aboutImg1 from '../../assets/img/about/1.jpg';
// changes 
import aboutImg1 from '../../assets/img/newimges/logodesign.jpg';
import aboutImg2 from '../../assets/img/newimges/carl-heyerdahl-KE0nC8-58MQ-unsplash.jpg';
// FlowChart Image
import Flowchart from '../../assets/img/flowchart/624cd884605ea2331cf5992b_7_step_web_design_process.png'
import '../../assets/scss/App.scss'

// Choose Us Image
// import whyChooseUsImg from '../../assets/img/choose/3.png';
// changes 
// import whyChooseUsImg from '../../assets/img/newimges/logodesign.jpg';

import SingleServiceTwologo from '../../src/components/Service/SingleServiceTwologo';
// import Service from '../home/ServiceSection';
//Helmet
import { Helmet } from 'react-helmet';
import PortfolioTab from '../../components/Elements/Tab/PortfolioTab';
import Project from './BrandIdentity/ProjectSection';

const WebDevelopmentContent = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title> Web design company in india
                    | Web design company in coimbatore
                    | Web design agency in india
                    | Web design agency in coimbatore
                    | Web design services in coimbatore
                    |top 10 Web design services in coimbatore
                    |top 10 Web design services in india
                    |top 10 Web design agency in coimbatore
                    |top 10 Web design agency in India
                </title>
                <meta name='description' content="Expert web design and development services utilizing HTML, CSS, JavaScript, and the MERN stack. We create responsive, high-performance websites for desktop and mobile, with seamless social media integration and ongoing support." />
                <meta name="keywords" content='web design, web development, HTML, CSS, JavaScript, MERN stack, responsive design, mobile optimization, social media integration, React.js, Node.js, full stack development, static websites, dynamic websites, website maintenance' />
            </Helmet>
            {/* About Section Start */}
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                // subtitle="Web Design"
                                titleClass="title"
                                title="Transform Your Brand Identity with Outstanding Logo Design"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="At Sadhvi Software Solutions, we transform your brand's vision into a captivating reality with our premier logo design services. Our approach involves crafting custom logos that embody your brand’s essence and effectively engage your audience. Committed to excellence, we ensure that each design not only meets your expectations but also elevates your brand’s identity in the market."
                            />
                            {/* Section Title End */}

                        </div>
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg1} alt="Best Logo Design Company in Coimbatore" />
                            </div>
                        </div>
                    </div>
                    <div className="btn-part mt-46">
                        <Link to="/contact">
                            <a className="readon learn-more">Contact Us</a>
                        </Link>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    {/* How we do Start */}
                    <SectionTitle
                        titleClass="title"
                        title="Our Services"
                        sectionClass="sec-title4"
                        descClass="desc desc-big"
                        description={(
                            <p>
                                <strong>Custom Logo Design:</strong> Craft a unique and memorable logo that captures your brand's essence and sets you apart from the competition. Our custom logo design solutions ensure your brand stands out with a professional and impactful identity.<br /><br />

                                <strong>Logo Development & Refinement:</strong> Enhance your existing logo or create a new one with our expert logo development and refinement services. We focus on perfecting every detail to ensure your logo effectively communicates your brand message.<br /><br />

                                <strong>Brand Identity Integration:</strong> Seamlessly integrate your logo into your overall brand identity with designs that enhance brand recognition and consistency across all platforms.<br /><br />

                                <strong>Logo Design for Digital & Print:</strong> Ensure your logo looks great everywhere, from digital platforms to print materials, with designs optimized for both web and print applications.
                            </p>

                        )}
                    />
                </div>
            </div>

            {/* How we do End */}

            {/* Flow Chart Start*/}
            {/* <div className='container_flowchart'>
                        <div className="image_flowchart">
                            <img src={Flowchart} alt="" />
                        </div>
                    </div> */}
            {/* Flow Chart Start*/}
            {/* <div className="rs-services style5 pt-10 md-pt-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 md-mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(20deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon1}
                                        Title="Expert People"
                                        Text="Our team of experts stays ahead of industry trends and provides 
                                        innovative solutions to help you achieve your branding goals."
                                    />
                                </div>
                                <div className="col-lg-4 md-mb-30"
                                    style={{
                                        display: 'inline-block',
                                        transition: 'transform 0.3s ease-in-out',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(20deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >

                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon2}
                                        Title="First Growing Process"
                                        Text="We leverage emerging technologies and strategic partnerships to offer
                                          solutions that helps to keep your brand at the forefront. "
                                    />
                                </div>
                                <div className="col-lg-4" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(20deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon3}
                                        Title="Creative Ideas"
                                        Text="Our creative approach allows us to tackle challenges with 
                                        innovative ideas and deliver designs that solve problems effectively. "
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}



            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg2} alt="Best Website development company in coimbatore" />
                            </div>
                        </div>
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}


                            {/* Section Title End */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                // subtitle="Web Design"
                                titleClass="title"
                                title="Best Logo Design Company in Coimbatore"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="At Sadhvi Software Solutions, we turn your vision into reality 
                                with high-quality logo design services. We aim to enhance your brand's identity
                                 and make a memorable impression in your market. Committed to excellence, 
                                 we provide exceptional customer service and support."
                            />
                        </div>

                    </div>

                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    {/* How we do Start */}
                    {/* <SectionTitle
                        titleClass="title"
                        title="How We Do"
                        sectionClass="sec-title4"
                        descClass="desc desc-big"
                        description={(
                            <p>
                                <strong>Logo Design:</strong> Elevate your brand with intuitive, customizable, and seamless logo design solutions.<br /><br />

                                <strong>Social Media Design:</strong> Enhance your online presence with captivating, engaging, and customized social media designs.<br /><br />

                                <strong>Brand Identity Design:</strong> Create a stunning and cohesive brand identity with impactful designs that resonate with your audience.<br /><br />

                                <strong>Web Design UI/UX:</strong> Deliver a seamless, intuitive, and powerful web design experience with UI/UX solutions that drive conversions.<br /><br />
                            </p>
                        )}
                    /> */}

                    {/* How we do End */}

                    {/* Flow Chart Start*/}
                    {/* <div className='container_flowchart'>
                        <div className="image_flowchart">
                            <img src={Flowchart} alt="" />
                        </div>
                    </div> */}
                    {/* Flow Chart Start*/}
             
                    <div className="rs-services style5 pt-10 md-pt-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 md-mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(2deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon1}
                                        Title="Exceptional Expertise "
                                        Text="Our team of expert logo designers pushes the boundaries of creativity, ensuring your logo stands out and resonates with your audience by staying ahead of industry trends." />
                                </div>
                                <div className="col-lg-4 md-mb-30"
                                    style={{
                                        display: 'inline-block',
                                        transition: 'transform 0.3s ease-in-out',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(2deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >

                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon2}
                                        Title="Advanced Process"
                                        Text="
                                       Using the latest design technology, we create logos that capture your brand’s essence with a forward-thinking approach to modern branding challenges. "
                                    />
                                </div>
                                <div className="col-lg-4" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(2deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon3}
                                        Title="Innovative Solutions"
                                        Text="We deliver unique logo design solutions that transform abstract concepts into compelling visuals, addressing branding challenges with creativity and precision. "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Project/>
            </div>
           
            {/* <div id="rs-service" className="rs-services main-home gray-bg pt-110 pb-120 md-pt-70 md-pb-70">
                <div className="container">

                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text"
                        // subtitle="Services"
                        titleClass="title"
                        title="Transform Your Business With Stunning Web Design"
                        effectClass="heading-line"
                    />
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleServiceTwologo
                                itemClass="services-item"
                                serviceImage={serviceIcon01}
                                serviceURL="web-design"
                                Title="Logo Design"
                                Text="Elevate your brand with intuitive, customizable, and seamless logo design solutions."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleService
                                itemClass="services-item"
                                serviceImage={serviceIcon03}
                                serviceURL="analytics-solution"
                                Title="Social Media Design"
                                Text="Elevate your online presence with captivating, engaging, and customized social media designs."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 mb-30"  style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleService
                                itemClass="services-item"
                                serviceImage={serviceIcon02}
                                serviceURL="analytics-solution"
                                Title="Brand Identity Design"
                                Text="Elevate your brand with stunning, cohesive, and impactful brand identity designs that resonate."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleService
                                itemClass="services-item"
                                serviceImage={serviceIcon04}
                                serviceURL="web-development"
                                Title="Web Design UI/UX"
                                Text="Elevate your brand with seamless, intuitive, and powerful web design UI/UX solutions that convert."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 offset-lg-3 offset-md-3" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleService
                                itemClass="services-item"
                                serviceImage={serviceIcon04}
                                serviceURL="digital-marketing"
                                Title="Strategic Digital Marketing"
                                Text="Advertise, Optimize and Analyze it for you to reach your desired target markets and drive more sales."
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* discoveryhome */}
            {/* <div className="rs-process pt-180 pb-120 md-pt-70 md-pb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 md-mb-40">
                        <div className="process-wrap">
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text new"
                                // subtitle="Working Process"
                                titleClass="title white-color"
                                title="Our Software Process Delivering Excellence to Clients"
                            />
                            <div className="btn-part mt-40">
                                <Link to="/contact" className="readon learn-more contact-us">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 pl-30 md-pl-15">
                        <div className="row">
                            <div className="col-md-6 mb-40">
                                <SingleProcess 
                                    processNumber="1. Discover"
                                    // processTitle="Discover"
                                    processText="Initially we gather project objectives, analyze customer needs, scope, deliverables, timeline, budget. Resources are identified and documented."
                                />                                
                            </div>
                            <div className="col-md-6 mb-40">                                
                                <SingleProcess 
                                    processNumber="2. Plan"
                                    // processTitle="Plan"
                                    processText="We generate project roadmap to design high level solutions to meet the business challenges and identify the target audience."
                                />
                            </div>
                            <div className="col-md-6 sm-mb-40">
                                <SingleProcess 
                                    processNumber="3. Execute"
                                    // processTitle="Execute"
                                    processText="Assign the task to team members, overlooking their progress and ensuring the quality of the project deliveries."
                                />
                            </div>
                            <div className="col-md-6">
                                <SingleProcess 
                                    processNumber="4. Deliver"
                                    // processTitle="Deliver"
                                    processText="Ensure the end product meets the expectation of the stakeholders and monitor the customer’s satisfaction with the product and evaluate its performance."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

            {/* Why Choose US Section Start */}

            {/* Why Choose US Section End */}

            {/* scrolltop-start */}
            {/* <ser */}
            <ScrollToTop />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}

export default WebDevelopmentContent;