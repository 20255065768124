import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ProjectMain from './ProjectMain';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/breadcrumbs/about.jpg';

const Projects = () => {

    return (
        <React.Fragment>
            <Header
                parentMenu='Projects'
                activeMenu="/projects"
            />
            <main>
                {/* breadcrumb-area-start */}
                <SiteBreadcrumb
                    pageTitle="Portfolios"
                    titleClass="page-title"
                    pageName="Portfolios"
                    breadcrumbsImg={bannerbg}
                />
                {/* breadcrumb-area-start */}

                <ProjectMain />
                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </main>
            <Footer />
            <SearchModal />
        </React.Fragment>
    );
}

export default Projects;