import { Link } from 'react-router-dom';
import SectionTitle from '../../../components/Common/SectionTitle';
// About Image Icons
import aboutImg from '../../../assets/img/banner/web-design-programming-homepage-website-concept_960782-41032.png';
// D:\SadhviSoft - Copy (2)\SadhviSoft - Copy\src\assets\img\banner\web-design-programming-homepage-website-concept_960782-41032.png
// Service Image Icons
// import leftShapeImg from '../../../assets/img/service/shape-2.png';
// import rightShapeImg from '../../../assets/img/service/shape-3.png';

const About = () => {

    return (
        <div id="rs-about" className="rs-about relative style3 pt-120 pb-120 md-pt-74 md-pb-80">
            <div className="container relative">
                <div className="row align-items-center">
                    <div className="col-lg-7 pr-56 md-pl-14 md-mb-30">
                        <SectionTitle
                            sectionClass="sec-title mb-30 zIndex"
                            subtitleClass="sub-text"
                            // subtitle="About Us"
                            titleClass="title title3 pb-30"
                            title="Sadhvi Software Solution - WordPress Development Services"
                            descClass="desc pb-30"
                            description="Boost Your Business Online with Expert Marketing and WordPress Solutions for Growth and Results"
                            secondDescClass="desc-part"
                            secondDescription="At Sadhvi Software Solution, we specialize in elevating your online presence through expert WordPress solutions. Our dedicated team ensures your website stands out in the digital realm, driving success and growth. Join us for a WordPress journey that transforms your online visibility and ensures marketing success"
                        />
                        <div className="btn-part mt-40">
                            <Link className="readon learn-more learn-btn" to="/contact">Learn More</Link>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="home8-about zIndex">
                            <img 
                                src={aboutImg}
                                alt="images" 
                            />
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="shape-part">
                <div className="left-side">
                    <img 
                        // src={leftShapeImg}
                        // alt="" 
                    /> 
                </div>
                <div className="right-side">
                    <img 
                        // src={rightShapeImg} 
                        // alt="" 
                    /> 
                </div>
            </div>
        </div>
    );
}

export default About;