import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import SectionTitle from '../../../components/Common/SectionTitle';
// Faq Image
import faqImage from '../../../assets/img/about/faq-question-information-helpdesk-graphic-word.jpg';

const FaqMain = () => {

    return (
        <React.Fragment>
            {/* Faq Part Start */}
            <div className="rs-faq gray-bg pt-50 md-pb-394 pb-150 md-pt-90 md-pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pr-40 md-pr-14">
                            <div className="images-part">
                                <img
                                    src={faqImage}
                                    alt="FAQ Graphic"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <SectionTitle
                                sectionClass="sec-title2 mb-44"
                                subtitleClass="sub-text style-bg"
                                titleClass="title title6"
                                title="Have Any Questions About Our Services?"
                            />
                            <Accordion className="accodion-style1" preExpanded={'b'}>
                                <AccordionItem uuid="a">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            What services does your software company offer?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>We provide a wide range of services including software development, UI/UX design, web development, mobile app development, and digital marketing solutions.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="b">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            How does your development process work?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Our development process involves understanding your business requirements, creating a detailed project plan, designing the architecture, coding, testing, and finally, deployment and ongoing support.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="c">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            What technologies do you specialize in?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>We specialize in a variety of technologies including React.js, Angular, Node.js, Python, WordPress, and various mobile development frameworks.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="d">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            How can your services benefit my business?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Our services can help your business by improving your digital presence, automating processes, enhancing customer experiences, and ultimately driving growth and profitability.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="e">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            How do I get started with your software services?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Getting started is easy. Simply contact us, and we’ll arrange a consultation to discuss your project needs, goals, and the best solutions for your business.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            {/* Faq Part End */}
        </React.Fragment>
    );
}

export default FaqMain;
