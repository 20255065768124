import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
import SingleServiceThree from '../../components/Service/SingleServiceThree';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

import serviceIcon1 from '../../assets/img/service/icons/web.png';
import serviceIcon2 from '../../assets/img/service/icons/brand.png';
import serviceIcon3 from '../../assets/img/service/icons/data.png';
import serviceIcon5 from '../../assets/img/service/icons/app_3097945.png';
import serviceIcon6 from '../../assets/img/service/icons/dm.png';
import aboutImg1 from '../../assets/img/newimges/homeimg2.jpg';


const Service = () => {

    return (


        <div id="rs-service" className="rs-services main-home gray-bg pt-110 pb-120 md-pt-70 md-pb-70">
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                // subtitle="Web Design"
                                titleClass="title"
                                title="Welcome to Sadhvi Software Solutions "
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="At Sadhvi Software Solutions, we are committed to
                                 delivering innovative technology solutions that drive success. 
                                 From custom web design and branding services to expert digital 
                                 marketing and advanced analytics solutions, our team of professionals
                                  is dedicated to enhancing your online presence and achieving your 
                                  business goals. "
                            />
                            {/* Section Title End */}

                        </div>
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg1} alt="Best Website development company in coimbatore" />
                            </div>
                        </div>
                    </div>
                    <div className="btn-part mt-46">
                        {/* <Link to="/contact">
                            <a className="readon learn-more">Contact Us</a>
                        </Link> */}
                    </div>
                    {/* <br></br>
                    <br></br>
                    <br></br> */}
                    <br></br>
                </div>
            </div>

            {/* old content */}
            <div className="container">

                <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                    subtitleClass="sub-text"
                    // subtitle="Services"
                    titleClass="title"
                    title="Comprehensive Business Growth Strategies"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-6 col-md-6 mb-30" style={{
                        display: 'inline-block',
                        transition: 'transform 0.3s ease-in-out',
                    }}
                        onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                        onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                        <SingleServiceTwo
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            serviceURL="/service/web-design/logo-design/"
                            Title="Web Design"
                            Text="Achieve exceptional user engagement and visual appeal with our custom web
                             design services, employing the latest techniques and standards."
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 mb-30" style={{
                        display: 'inline-block',
                        transition: 'transform 0.3s ease-in-out',
                    }}
                        onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                        onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                        <SingleServiceThree
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            serviceURL="/service/web-design"
                            Title="Web Development"
                            Text="Build a strong online presence with our dynamic web development solutions, 
                            featuring responsive and scalable functionality."
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 mb-30" style={{
                        display: 'inline-block',
                        transition: 'transform 0.3s ease-in-out',
                    }}
                        onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                        onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                        <SingleServiceFour
                            itemClass="services-item"
                            serviceImage={serviceIcon2}
                            serviceURL="/service/web-development"
                            Title="Brand Identity Design"
                            Text="Enhance your market presence with unique brand identity design, crafted
                             to elevate recognition and connect with your audience."
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 sm-mb-30" style={{
                        display: 'inline-block',
                        transition: 'transform 0.3s ease-in-out',
                    }}
                        onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                        onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                        <SingleServiceFive
                            itemClass="services-item"
                            serviceImage={serviceIcon5}
                            serviceURL="/service/machine-learning"
                            Title="Analytics Solutions"
                            Text="Gain actionable insights and drive growth with our advanced analytics solutions, 
                            designed to unlock key data for strategic decisions."
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 offset-lg-3 offset-md-3" style={{
                        display: 'inline-block',
                        transition: 'transform 0.3s ease-in-out',
                    }}
                        onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                        onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                        <SingleServiceSix
                            itemClass="services-item"
                            serviceImage={serviceIcon6}
                            serviceURL="/service/digital-marketing"
                            Title={
                                <span style={{
                                    fontSize: '24px',
                                    fontWeight: 600,
                                    lineHeight: '30px',
                                    marginBottom: '10px',

                                }}>
                                    Strategic Digital Marketing
                                </span>
                            }
                            Text="Boost your reach and sales with our strategic digital marketing services, 
                            focused on advertising, optimization, and performance analysis."
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;