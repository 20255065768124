import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';


// Breadcrumb Image
import bannerbg from '../../assets/img/breadcrumbs/prot3.jpg';

//Helmet
import { Helmet } from 'react-helmet';


const CaseStudtyStyleThree = () => {

    return (
        <React.Fragment>
        <Helmet>
            <title> Decision Tress, Ensemble Techniques are used in Sadhvi Software Solutions|Coimbatore </title>
            <meta name='description' content="To develop a predictive model that the customer was likely to subscribe term deposit and demographic information."/>
            <meta name="keywords" content='machine learning,python,,scikit-learn,Pandas,
            Natural language processing,decisionm trees,Matplotlib,projects,seaborn,ensemble techniques,classification,
            task.'/>
        </Helmet>
            {/* Header */}
            <Header
                parentMenu='page'
                secondParentMenu='case-studies'
                activeMenu='/case-studies-3'
            />
            {/* Header */}

            {/* SiteBreadcrumb */}
            <SiteBreadcrumb
                pageTitle="Predicting the term deposit Subscription"
                pageName="Predicting the term deposit Subscription"
                breadcrumbsImg={bannerbg}
            />
            {/* SiteBreadcrumb */}


            <div className='container_casestudy'>
                <p>
                    <h3>Challenge</h3>
                    We understood that predicting the likelihood of a customer subscribing to a term deposit was a complex task. Our project involved predicting customer subscription rates for term-deposit products, using historical data on customer characteristics and behavior. Predictive models were built to accurately predict customer behavior and strategies were developed to increase customer subscription rates.<br></br>
                    
                    <h3>Scope and Motivation</h3>
                    
                    The purpose of this project was to predict whether a customer would subscribe to a term deposit or not. The scope of this project was to develop a predictive model that could accurately and reliably predict whether a customer was likely to subscribe to a term deposit or not based on the customer’s characteristics, such as age, occupation, marital status, and other demographic information. The model was able to provide insights into which customer characteristics were important in determining subscription probability. The model was also able to provide insights about customer behavior to better target potential customers. The ultimate goal of this project was to be able to predict customer behavior to increase the success rate of term deposit subscriptions.<br></br>
                      
                    <h3>Solution</h3>
                    The first step for predicting the term-deposit subscription project solutions was to gather the data. This involved collecting customer information such as age, occupation, income level, and other relevant data points. Once collected, the data was cleaned and pre-processed to ensure accuracy and consistency. Various machine learning algorithms were then used to analyze the data, such as decision trees, logistic regression, and support vector machines. The goal of these algorithms was to identify patterns in the data that could be used to accurately predict whether a customer would subscribe to a term deposit. Finally, the results were evaluated to determine the accuracy of the predictions.
                    
                    <h3>Technologies and Tools </h3>
                   <strong>Technologies:</strong>: Machine Learning (Classification, Decision Tress, Ensemble Techniques), Natural Language Processing (NLP).<br></br>
                    <strong>Tools:</strong>Python, Scikit-learn, Pandas, Matplotlib, Seaborn.
                </p>
                        
            </div>


            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            {/* Footer */}
            <Footer />
            {/* Footer */}

            {/* SearchModal */}
            <SearchModal />
            {/* SearchModal */}
        </React.Fragment>

    );
}


export default CaseStudtyStyleThree;