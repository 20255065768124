import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/5.jpg';
import MachineLearningContent from './MachineLearningContent';
import ContactMain from '../../src/pages/service/DigitalMarketing/ContactMain';


const MachineLearning = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='service'
                activeMenu='/project-design'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Analytics Solutions'
                pageName='Analytics Solutions'
            />
            {/* breadcrumb-area-end */}

            <MachineLearningContent />
<ContactMain/>
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default MachineLearning;