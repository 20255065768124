import React  from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcess from '../../components/Common/Process';

const Process = () => {

    return (
        <div className="rs-process pt-180 pb-120 md-pt-70 md-pb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 md-mb-40">
                        <div className="process-wrap">
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text new"
                                // subtitle="Working Process"
                                titleClass="title white-color"
                                title="Our Software Process: Delivering Excellence to Clients"
                            />
                            <div className="btn-part mt-40">
                                <Link to="/contact" className="readon learn-more contact-us">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 pl-30 md-pl-15">
                        <div className="row">
                            <div className="col-md-6 mb-40">
                                <SingleProcess 
                                    processNumber="1. Discover"
                                    // processTitle="Discover"
                                    processText="We create a project roadmap, designing solutions that address
                                     challenges and target the right audience, helping us meet your goals."
                                />                                
                            </div>
                            <div className="col-md-6 mb-40">                                
                                <SingleProcess 
                                    processNumber="2. Plan"
                                    // processTitle="Plan"
                                    processText="We create a project roadmap, designing solutions that 
                                    address business challenges and target the right audience and meet your goals."
                                />
                            </div>
                            <div className="col-md-6 sm-mb-40">
                                <SingleProcess 
                                    processNumber="3. Execute"
                                    // processTitle="Execute"
                                    processText="Our team works on the project tasks, ensuring quality and timely delivery. 
                                    We manage progress closely to achieve the best results."
                                />
                            </div>
                            <div className="col-md-6">
                                <SingleProcess 
                                    processNumber="4. Deliver"
                                    // processTitle="Deliver"
                                    processText="We make sure the final product meets your expectations. We check customer
                                     satisfaction and evaluate the performance to ensure success."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Process;