import SectionTitle from '../../../../components/Common/SectionTitle';
import SingleServiceBox from '../../../../components/Service/SingleServiceBox';

// Service Icons
import img1 from '../../../../assets/img/technology/style3/1.png';
import img2 from '../../../../assets/img/technology/style3/2.png';
import img3 from '../../../../assets/img/technology/style3/3.png';
import img4 from '../../../../assets/img/technology/style3/4.png';
import img5 from '../../../../assets/img/technology/style3/5.png';
import img6 from '../../../../assets/img/technology/style3/6.png';
import img7 from '../../../../assets/img/technology/style3/7.png';
import img8 from '../../../../assets/img/technology/style3/8.png';
import img9 from '../../../../assets/img/technology/style3/9.png';
import img10 from '../../../../assets/img/technology/style3/10.png';
import img11 from '../../../../assets/img/technology/style3/11.png';
import img12 from '../../../../assets/img/technology/style3/12.png';
import img13 from '../../../../assets/img/technology/style3/13.png';
import img14 from '../../../../assets/img/technology/style3/14.png';
import img15 from '../../../../assets/img/technology/style3/15.png';

const Service = () => {

    return (
        <div className="rs-services style8 pt-120 pb-114 md-pt-70 md-pb-70" style={{  paddingtop: '40px !important' }}>
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text gold-color"
                    // subtitle="Work For Any Industry"
                    titleClass="title"
                    title="Boost Your Website's Visibility with SEO Services"
                />
                <div className="all-services">
                    <SingleServiceBox
                        serviceIcon={img1}
                        Title="Keyword Research" 
                    />
                    <SingleServiceBox
                        serviceIcon={img2}
                        Title="On-Page Optimization" 
                    />
                    <SingleServiceBox
                        serviceIcon={img3}
                        Title="Technical SEO" 
                    />
                    <SingleServiceBox
                        serviceIcon={img4}
                        Title="Off-Page Optimization" 
                    />
                    <SingleServiceBox
                        serviceIcon={img5}
                        Title="Content Creation and Optimization" 
                    />
                    <SingleServiceBox
                        serviceIcon={img6}
                        Title="Local SEO" 
                    />
                    <SingleServiceBox
                        serviceIcon={img7}
                        Title="SEO Audits and Analysis" 
                    />
                    <SingleServiceBox
                        serviceIcon={img8}
                        Title="Monitoring and Reporting" 
                    />
                    <SingleServiceBox
                        serviceIcon={img9}
                        Title="SEO Consulting and Strategy" 
                    />
                    <SingleServiceBox
                        serviceIcon={img10}
                        Title="Online Reputation Management" 
                    />
                    <SingleServiceBox
                        serviceIcon={img11}
                        Title="SEO for Social Media" 
                    />
                    <SingleServiceBox
                        serviceIcon={img12}
                        Title="SEO Copywriting" 
                    />
                    <SingleServiceBox
                        serviceIcon={img13}
                        Title="Link Building" 
                    />
                    <SingleServiceBox
                        serviceIcon={img14}
                        Title="Website Analytics" 
                    />
                    <SingleServiceBox
                        serviceIcon={img15}
                        Title="Blogging" 
                    />
                </div>
            </div>
        </div>
    );
}

export default Service;