import React from 'react';
import Header from '../../../components/Layout/Header';
import Footer from '../../../components/Layout/Footer';
import SearchModal from '../../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';

import BannerHomeTwelve from './BannerHomeTwelve';
import About from './AboutSection';
import Project from './ProjectSection';

// Breadcrumbs Image
import breadcrumbsImg from '../../../assets/img/breadcrumbs/4.jpg';
import BrandIdentity from './BrandIdentity';
import ContactMain from '../../../src/pages/service/DigitalMarketing/ContactMain';


const brandidentity = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='service'
                activeMenu='/brand-identity'
            />
            <BannerHomeTwelve />
            <BrandIdentity />
            <About />
            {/* Project-area-start */}
			<Project />
            <ContactMain/>
			{/* Project-area-end */}
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default brandidentity;