import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';
import ProgressBar from '../../components/Elements/Progress/ProgressBar';
import ScrollToTop from '../../components/Common/ScrollTop';
import SingleProcess from '../../components/Common/Process';
// Service Image
import serviceIcon1 from '../../assets/img/service/style7/1.png';
import serviceIcon2 from '../../assets/img/service/style7/2.png';
import serviceIcon3 from '../../assets/img/service/style7/3.png';

import serviceIcon01 from '../../assets/img/icon/logo-design.png';
import serviceIcon02 from '../../assets/img/icon/mobile-app.png';
import serviceIcon03 from '../../assets/img/icon/brandidentitydesign.png';
import serviceIcon04 from '../../assets/img/icon/ui-ux.png';
// import aboutImg1 from '../../assets/img/about/1.jpg';
// changes 
import aboutImg1 from '../../assets/img/webdevimg/woocomerce1.jpg';
import aboutImg2 from '../../assets/img/webdevimg/woocomerce2.jpg';
// FlowChart Image
import Flowchart from '../../assets/img/flowchart/624cd884605ea2331cf5992b_7_step_web_design_process.png'
import '../../assets/scss/App.scss'

// Choose Us Image
// import whyChooseUsImg from '../../assets/img/choose/3.png';
// changes 
// import whyChooseUsImg from '../../assets/img/newimges/logodesign.jpg';

import SingleServiceTwologo from '../../src/components/Service/SingleServiceTwologo';
//Helmet
import { Helmet } from 'react-helmet';
import Service from './WordPress/ServiceSection';

const WebDevelopmentContent = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title> Web design company in india
                    | Web design company in coimbatore
                    | Web design agency in india
                    | Web design agency in coimbatore
                    | Web design services in coimbatore
                    |top 10 Web design services in coimbatore
                    |top 10 Web design services in india
                    |top 10 Web design agency in coimbatore
                    |top 10 Web design agency in India
                </title>
                <meta name='description' content="Expert web design and development services utilizing HTML, CSS, JavaScript, and the MERN stack. We create responsive, high-performance websites for desktop and mobile, with seamless social media integration and ongoing support." />
                <meta name="keywords" content='web design, web development, HTML, CSS, JavaScript, MERN stack, responsive design, mobile optimization, social media integration, React.js, Node.js, full stack development, static websites, dynamic websites, website maintenance' />
            </Helmet>
            {/* About Section Start */}
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                // subtitle="Web Design"
                                titleClass="title"
                                title="Boost Your Online Sales with Expert WooCommerce Development"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="At Sadhvi Software Solutions, we transform your e-commerce vision into a thriving reality with our specialized WooCommerce development services. Our approach is centered on creating custom, scalable, and user-friendly WooCommerce stores that drive sales and enhance customer experience. Our commitment to excellence ensures that each store we build not only meets your expectations but also accelerates your business growth."
                            />
                            {/* Section Title End */}

                        </div>
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg1} alt="Best WooCommerce Development Company in Coimbatore" />
                            </div>
                        </div>
                    </div>
                    <div className="btn-part mt-46">
                        <Link to="/contact">
                            <a className="readon learn-more">Contact Us</a>
                        </Link>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    {/* How we do Start */}
                    <SectionTitle
                        titleClass="title"
                        title="Our Services"
                        sectionClass="sec-title4"
                        descClass="desc desc-big"
                        description={(
                            <p>
                            <strong>Custom WooCommerce Store Development:</strong> Develop a unique and powerful WooCommerce store tailored to your business needs. Our custom solutions ensure your e-commerce platform stands out with a professional and seamless shopping experience.<br /><br />
                        
                            <strong>WooCommerce Theme Design & Customization:</strong> Whether you need a new theme or want to customize an existing one, our team will deliver a theme that reflects your brand and enhances your store’s functionality.<br /><br />
                        
                            <strong>Responsive Design & Mobile Optimization:</strong> Ensure your WooCommerce store provides an optimal shopping experience on all devices. We specialize in creating responsive designs that are smooth and user-friendly across desktops, tablets, and mobile phones.<br /><br />
                        
                            <strong>WooCommerce Plugin Integration & Customization:</strong> Extend your store’s capabilities with customized plugins. Our expertise in plugin integration and development allows us to enhance your store’s functionality and improve customer satisfaction.<br /><br />
                        </p>
                        

                        )}
                    />

                    {/* How we do End */}

                    {/* Flow Chart Start*/}
                    {/* <div className='container_flowchart'>
                        <div className="image_flowchart">
                            <img src={Flowchart} alt="" />
                        </div>
                    </div> */}
                    {/* Flow Chart Start*/}
                    <div className="rs-services style5 pt-10 md-pt-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 md-mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(20deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon1}
                                        Title="Expert People"
                                        Text="Our team of WooCommerce experts stays ahead of the latest e-commerce
                                         trends and technologies, offering innovative solutions to help 
                                           sales goals."
                                    />
                                </div>
                                <div className="col-lg-4 md-mb-30"
                                    style={{
                                        display: 'inline-block',
                                        transition: 'transform 0.3s ease-in-out',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(20deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >

                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon2}
                                        Title="First Growing Process"
                                        Text="We leverage cutting-edge technologies and strategic partnerships to 
                                        offer solutions that keep your WooCommerce store competitive and growth-oriented. "
                                    />
                                </div>
                                <div className="col-lg-4" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(20deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon3}
                                        Title="Creative Ideas"
                                        Text="Our creative approach enables us to tackle e-commerce challenges with innovative ideas,
                                         delivering WooCommerce stores that not only look great but also function flawlessly. "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg2} alt="Best Website development company in coimbatore" />
                            </div>
                        </div>
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}


                            {/* Section Title End */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                // subtitle="Web Design"
                                titleClass="title"
                                title="Best WooCommerce Development Company in Coimbatore for WooCommerce Website "
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="At Sadhvi Software Solutions, we bring your e-commerce vision to life with
                                 high-quality WooCommerce development services. We aim to enhance your online store’s 
                                 performance and create a memorable shopping experience for your customers. 
                                Committed to excellence, we provide exceptional customer service and support."
                            />
                        </div>

                    </div>

                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    {/* How we do Start */}
                    {/* <SectionTitle
                        titleClass="title"
                        title="How We Do"
                        sectionClass="sec-title4"
                        descClass="desc desc-big"
                        description={(
                            <p>
                                <strong>Logo Design:</strong> Elevate your brand with intuitive, customizable, and seamless logo design solutions.<br /><br />

                                <strong>Social Media Design:</strong> Enhance your online presence with captivating, engaging, and customized social media designs.<br /><br />

                                <strong>Brand Identity Design:</strong> Create a stunning and cohesive brand identity with impactful designs that resonate with your audience.<br /><br />

                                <strong>Web Design UI/UX:</strong> Deliver a seamless, intuitive, and powerful web design experience with UI/UX solutions that drive conversions.<br /><br />
                            </p>
                        )}
                    /> */}

                    {/* How we do End */}

                    {/* Flow Chart Start*/}
                    {/* <div className='container_flowchart'>
                        <div className="image_flowchart">
                            <img src={Flowchart} alt="" />
                        </div>
                    </div> */}
                    {/* Flow Chart Start*/}
                    <Service/>
                    {/* <div className="rs-services style5 pt-10 md-pt-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 md-mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(2deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon1}
                                        Title="Exceptional Expertise"
                                        Text="Our team of experienced WooCommerce developers is dedicated to pushing the 
                                        boundaries of
                                         e-commerce innovation. Ahead of
                                         industry trends, we ensure that your WooCommerce store not only stands out but also drives conversions and sales."
                                    />
                                </div>
                                <div className="col-lg-4 md-mb-30"
                                    style={{
                                        display: 'inline-block',
                                        transition: 'transform 0.3s ease-in-out',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(2deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >

                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon2}
                                        Title="Advanced Process"
                                        Text="
                                       Latest advancements in e-commerce technology, we employ a forward-thinking 
                                       approach to create WooCommerce stores that capture the essence of your brand. Our strategic
                                        process is designed to address the complexities of online retail with precision."
                                    />
                                </div>
                                <div className="col-lg-4" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(2deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                                >
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon3}
                                        Title="Innovative Solutions"
                                        Text="We pride ourselves on our ability to think outside the box. Our innovative 
                                        WooCommerce solutions are crafted to address unique e-commerce
                                         challenges, transforming concepts into visually compelling and high-performing online stores."
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            {/* <div id="rs-service" className="rs-services main-home gray-bg pt-110 pb-120 md-pt-70 md-pb-70">
                <div className="container">

                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text"
                        // subtitle="Services"
                        titleClass="title"
                        title="Transform Your Business With Stunning Web Design"
                        effectClass="heading-line"
                    />
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleServiceTwologo
                                itemClass="services-item"
                                serviceImage={serviceIcon01}
                                serviceURL="web-design"
                                Title="Logo Design"
                                Text="Elevate your brand with intuitive, customizable, and seamless logo design solutions."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleService
                                itemClass="services-item"
                                serviceImage={serviceIcon03}
                                serviceURL="analytics-solution"
                                Title="Social Media Design"
                                Text="Elevate your online presence with captivating, engaging, and customized social media designs."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 mb-30"  style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleService
                                itemClass="services-item"
                                serviceImage={serviceIcon02}
                                serviceURL="analytics-solution"
                                Title="Brand Identity Design"
                                Text="Elevate your brand with stunning, cohesive, and impactful brand identity designs that resonate."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 mb-30" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleService
                                itemClass="services-item"
                                serviceImage={serviceIcon04}
                                serviceURL="web-development"
                                Title="Web Design UI/UX"
                                Text="Elevate your brand with seamless, intuitive, and powerful web design UI/UX solutions that convert."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 offset-lg-3 offset-md-3" style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleService
                                itemClass="services-item"
                                serviceImage={serviceIcon04}
                                serviceURL="digital-marketing"
                                Title="Strategic Digital Marketing"
                                Text="Advertise, Optimize and Analyze it for you to reach your desired target markets and drive more sales."
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* discoveryhome */}
            {/* <div className="rs-process pt-180 pb-120 md-pt-70 md-pb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 md-mb-40">
                        <div className="process-wrap">
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text new"
                                // subtitle="Working Process"
                                titleClass="title white-color"
                                title="Our Software Process Delivering Excellence to Clients"
                            />
                            <div className="btn-part mt-40">
                                <Link to="/contact" className="readon learn-more contact-us">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 pl-30 md-pl-15">
                        <div className="row">
                            <div className="col-md-6 mb-40">
                                <SingleProcess 
                                    processNumber="1. Discover"
                                    // processTitle="Discover"
                                    processText="Initially we gather project objectives, analyze customer needs, scope, deliverables, timeline, budget. Resources are identified and documented."
                                />                                
                            </div>
                            <div className="col-md-6 mb-40">                                
                                <SingleProcess 
                                    processNumber="2. Plan"
                                    // processTitle="Plan"
                                    processText="We generate project roadmap to design high level solutions to meet the business challenges and identify the target audience."
                                />
                            </div>
                            <div className="col-md-6 sm-mb-40">
                                <SingleProcess 
                                    processNumber="3. Execute"
                                    // processTitle="Execute"
                                    processText="Assign the task to team members, overlooking their progress and ensuring the quality of the project deliveries."
                                />
                            </div>
                            <div className="col-md-6">
                                <SingleProcess 
                                    processNumber="4. Deliver"
                                    // processTitle="Deliver"
                                    processText="Ensure the end product meets the expectation of the stakeholders and monitor the customer’s satisfaction with the product and evaluate its performance."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

            {/* Why Choose US Section Start */}

            {/* Why Choose US Section End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}

export default WebDevelopmentContent;