import { Link } from 'react-router-dom';

import bgImg from '../../../../assets/img/bg/choose-bg.jpg';

const WhyChooseUs = () => {
    const bgStyle = {
        backgroundImage:`url(${bgImg})`
    }


    return (
        <div className="rs-why-choose style3 pt-120 pb-120 md-pt-74 md-pb-80" style={bgStyle}>
            <div className="container relative">
                <div className="sec">
                    <h1 className="title white-color">Why Should You Use Content Marketing? Unlocking the Benefits</h1>
                </div>
                <br></br>
                <div className="row align-items-center">
                    <div className="col-lg-12 md-pb-30">
                        <div className="features-content">
                            <ul className="features-list">
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>
                                    Content marketing helps build brand recognition, visibility, and credibility among your target audience</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Engaging and valuable content fosters meaningful connections with your audience, leading to higher engagement and loyalty</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i><span>
                                    High-quality content optimized for SEO increases your website's visibility in search engine results, driving organic traffic</span>
                                </li>
                                <li><i className="fa fa-check"></i>
                                <span>Compelling content attracts and nurtures leads, driving conversions and sales for your business</span></li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Creating insightful and authoritative content positions your brand as a trusted industry leader and enhances your reputation</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i><span>
                                    Valuable content educates and solves problems for your audience, establishing trust and long-term relationships</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Content marketing allows you to tailor content to specific audience segments, ensuring relevance and resonance</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span> Engaging and shareable content has the potential to reach wider audiences through social sharing and word-of-mouth</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Compared to traditional advertising, content marketing is a cost-effective strategy that delivers long-term value and ROI</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;