import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';

import ProgressBar from '../../components/Elements/Progress/ProgressBar';
import ScrollToTop from '../../components/Common/ScrollTop';

// Service Image
import serviceIcon1 from '../../assets/img/service/style7/1.png';
import serviceIcon2 from '../../assets/img/service/style7/2.png';
import serviceIcon3 from '../../assets/img/service/style7/3.png';

import aboutImg1 from '../../assets/img/about/1.jpg';

// FlowChart Image
import Flowchart from '../../assets/img/flowchart/Web_development.png'
import '../../assets/scss/App.scss'

// Choose Us Image
// import whyChooseUsImg from '../../assets/img/choose/3.png';
// changes 
import whyChooseUsImg from '../../assets/img/newimges/Increasing Business.avif';
//Helmet
import { Helmet } from 'react-helmet';

const WordPressDevelopmentContent = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title> Web Development company in coimbatore</title>
                <meta name='description' content="Provide high quqlity web services using our top technical tools like HTML,CSS
                Javascript,MERN stack and also performs in all social media platforms.Easy handling in both desktop and mobile usage."/>
                <meta name="keywords" content='HTML,CSS,Javascript,MERNSTACK,Smartphones,Tablets,Maintenance,Testing,Quality,deploy,launch,facebook,linkedin,twitter,instagram,
                Reactjs,Front end developmemt,Backend development,Full Stack,nodejs, static Website,dynamic website '/>
            </Helmet>
            {/* About Section Start */}

            {/* Why Choose US Section End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}

export default WordPressDevelopmentContent;