import React from 'react';
import SectionTitle from '../../../components/Common/SectionTitle';
import ContactForm from '../../../components/Contact/ContactForm';
import ContactInfo from '../../../components/Contact/ContactInfo';
import ScrollToTop from '../../../components/Common/ScrollTop';



const ContactMain = () => {

        return (
            <React.Fragment>
            
                {/* Contact Section Start */}
                <div className="rs-contact pt-120 md-pt-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 md-mb-60">
                                <ContactInfo />
                            </div>
                            <div className="col-lg-8 pl-70 md-pl-14">
                                <div className="contact-widget">
                                    <SectionTitle
                                        sectionClass="sec-title2 mb-40"
                                        subtitleClass="sub-text contact mb-14"
                                        // subtitle="Get In Touch"
                                        titleClass="title testi-title"
                                        title="Fill The Form Below"
                                    />

                                    {/* Contact Form */}
                                    <ContactForm />
                                    {/* Contact Form */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
                {/* Contact Section End */}
            </React.Fragment>

        );
}


export default ContactMain;

// <iframe src=""></iframe>