import { Link } from 'react-router-dom';

import bgImg from '../../../../assets/img/bg/choose-bg.jpg';

const WhyChooseUs = () => {
    const bgStyle = {
        backgroundImage:`url(${bgImg})`
    }


    return (
        <div className="rs-why-choose style3 pt-120 pb-120 md-pt-74 md-pb-80" style={bgStyle}>
            <div className="container relative">
                <div className="sec">
                    <h1 className="title white-color">Why Should You Use Email Marketing? Unlocking the Benefits</h1>
                </div>
                <br></br>
                <div className="row align-items-center">
                    <div className="col-lg-12 md-pb-30">
                        <div className="features-content">
                            <ul className="features-list">
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>
                                    Email marketing allows you to directly reach your audience in their inbox, ensuring your message receives maximum visibility and engagement</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Compared to traditional marketing channels, email marketing offers a high ROI with low costs, making it an efficient marketing strategy for businesses of all sizes</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i><span>
                                    With email segmentation and personalization, you can tailor content and offers based on subscriber preferences, increasing relevance and engagement</span>
                                </li>
                                <li><i className="fa fa-check"></i>
                                <span>Regularly sending valuable content and offers fosters long-term relationships, building trust, and increasing customer loyalty</span></li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Emails provide opportunities to drive conversions, whether it's making a purchase, signing up for an event, or downloading content</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i><span>
                                    Email marketing platforms offer comprehensive analytics to track metrics like open rates, click-through rates, and conversions, providing insights for optimization</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Automated email workflows save time and effort by sending targeted messages based on specific triggers or customer actions</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Consistent email communication keeps your brand top of mind, reinforcing brand awareness and recognition among subscribers</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Email marketing can be easily scaled as your business grows, allowing you to reach a larger audience without significant resource investment</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;