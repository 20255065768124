import SectionTitle from '../../../../components/Common/SectionTitle';
import SingleServiceBox from '../../../../components/Service/SingleServiceBox';

// Service Icons
import img1 from '../../../../assets/img/technology/style7/1.png';
import img2 from '../../../../assets/img/technology/style7/2.png';
import img3 from '../../../../assets/img/technology/style7/3.png';
import img4 from '../../../../assets/img/technology/style7/4.png';
import img5 from '../../../../assets/img/technology/style7/5.png';
import img6 from '../../../../assets/img/technology/style7/6.png';
import img7 from '../../../../assets/img/technology/style7/7.png';
import img8 from '../../../../assets/img/technology/style7/8.png';
import img9 from '../../../../assets/img/technology/style7/9.png';
import img10 from '../../../../assets/img/technology/style7/10.png';

const Service = () => {

    return (
        <div className="rs-services style8 " style={{ paddingtop: '40px !important' }}>
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text gold-color"
                    // subtitle="Work For Any Industry"
                    titleClass="title"
                    title="Boost Your Website's Visibility with Content Marketing Services"
                />
                <div className="all-services">
                    <SingleServiceBox
                        serviceIcon={img1}
                        Title="Content Promotion " 
                    />
                    <SingleServiceBox
                        serviceIcon={img2}
                        Title="Content Creation" 
                    />
                    <SingleServiceBox
                        serviceIcon={img3}
                        Title="Content Optimization" 
                    />
                    <SingleServiceBox
                        serviceIcon={img4}
                        Title="Content Distribution" 
                    />
                    <SingleServiceBox
                        serviceIcon={img5}
                        Title="Content Strategy Development" 
                    />
                    <SingleServiceBox
                        serviceIcon={img6}
                        Title="Content Management" 
                    />
                    <SingleServiceBox
                        serviceIcon={img7}
                        Title="Content Performance Measurement" 
                    />
                    <SingleServiceBox
                        serviceIcon={img8}
                        Title="Content Audits and Strategy Updates" 
                    />
                    <SingleServiceBox
                        serviceIcon={img9}
                        Title="Content Gap Analysis" 
                    />
                    <SingleServiceBox
                        serviceIcon={img10}
                        Title="Email Newsletter Content" 
                    />
                    
                </div>
            </div>
        </div>
    );
}

export default Service;