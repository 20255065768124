import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';


// Breadcrumb Image
import bannerbg from '../../assets/img/breadcrumbs/prot4.jpg';


//helmet
import { Helmet } from 'react-helmet';


const CaseStudtyStyleFour = () => {

    return (
        <React.Fragment>
        <Helmet>
            <title> Website Designing for Aksharam School|Coimbatore|Sadhvi Software Solutions  </title>
            <a href="https://aksharamschool.org/"> Aksharam International School, Coimbatore </a>
            <meta name='description' content="To create an appealing and funtional website design and generate a good SEO for Aksharam International School, Coimbatore."/>
            <meta name="keywords" content='machine learning,python,tensor-flow,scikit-learn,Pandas,
            Natural language processing,deep learning,collabarative filtering,amazon products,projects,
            recommendation systems'/>
        </Helmet>
            {/* Header */}
            <Header
                parentMenu='page'
                secondParentMenu='case-studies'
                activeMenu='/case-studies-4'
            />
            {/* Header */}

            {/* SiteBreadcrumb */}
            <SiteBreadcrumb
                pageTitle="Website Design for Aksharam International School"
                pageName="Website Design for Aksharam International School"
                breadcrumbsImg={bannerbg}
            />
            {/* SiteBreadcrumb */}



            <div className='container_casestudy'>
                <p>
                    
                    <h3>Challenge</h3>
                    Aksharam International School provided educational opportunities to students across Coimbatore regions. The goal of this project was to create an international school system that focused on the development of global citizens and provided access to quality education for everyone. The website design reflected the mission of the project and provided a platform for students to learn and explore the world.<br></br>
                    
                    <h3>Scope and Motivation</h3>
                    
                    The main objective of this website design project was to create an appealing, modern, and functional website for Aksharam International School. The website served as an online presence for the school and provided an effective online presence and served as a valuable resource for information about the school, its programs, and activities to prospective students and their families.<br></br>
                      
                    <h3>Solution</h3>
                    <ul> 
                        <li>Aksharam International School had been an educational institution that offered a range of courses and programs, along with a host of features and advantages. Our website provided an interactive map with school locations, a comprehensive list of courses and programs an overview of school features, an introduction to our faculty and staff, and a directory of contact information. Furthermore, we provided parents with resources, hosted events, and activities, and featured a blog, store, news section, photo and video section, testimonials, FAQs, and a forum. We also offered information about scholarships and financial aid and had a searchable and user-friendly website design.</li><br></br>
                                
                       <strong>Responsive Design:</strong>  Ensured that the website was accessible and easy to use on any device, so we found a responsive design. This means that the website have been optimized for mobile, tablet, and desktop devices. The layout have adjusted, so that the screen size and the navigation has remained intuitive and easy to use. <br></br>

                       <strong>Content Management System (CMS):</strong> We used CMS to manage the content of the website. This has allowed the website to easily update and maintain without the need for technical expertise. <br></br>

                       <strong>Logo Design:</strong>  We designed custom logos for this organization, to suit their needs. <br></br>

                       <strong>User Experience Design: </strong> Created a user experience strategy that includes wireframes to illustrate the structure of past websites, and user flows to map out the user's journey.<br></br>

                        <strong>User Interface Design:</strong> We gave a modern website Look and Feel Effect, including a Colour Palette, Typography, Imagery, and elements such as Buttons and Forms, that is essential to create a memorable user experience.<br></br>
                    </ul> 

                    <h3>Technologies and Tools  </h3>

                    Adobe Products, Figma, JavaScript, HTML & CSS.

                </p>  
                            
            </div>

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            {/* Footer */}
            <Footer />
            {/* Footer */}

            {/* SearchModal */}
            <SearchModal />
            {/* SearchModal */}
        </React.Fragment>

    );
}


export default CaseStudtyStyleFour;