import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../../components/Common/SectionTitle/SectionTitleTwo';
import SingleTestimonial5 from '../../../components/Testimonial/SingleTestimonial5';
// import CaseStyleThree from '../../components/CaseStudy/CaseStyleThree';

// Testimonial Images
import quote from '../../../assets/img/testimonial/quote11.png';
import rating from '../../../assets/img/testimonial/rating.png';

// Testimonial Avatars
import author1 from '../../../assets/img/testimonial/1.jpg';
import author2 from '../../../assets/img/testimonial/2.jpg';
import author3 from '../../../assets/img/testimonial/3.jpg';
import author4 from '../../../assets/img/testimonial/4.jpg';

// import author5 from '../../../assets/img/testimonial/5.jpg';

const Testimonial = () => {
    const options = {
        items: 4,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        loop: true,
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                dots: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                dots: false,
            },
            992: {
                items: 2,
                stagePadding: 0,
                dots: false,
            },
            1200: {
                items: 3,
                stagePadding: 0,
                dots: true,
            },
            1500: {
                items: 4,
                stagePadding: 0,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-team" className="rs-testimonial style6 gray-bg pt-100 pb-100 md-pt-80 md-pb-80 md-pl-14 md-pr-14">
                <SectionTitle 
                    title={<>Clients <span>Testimonials</span></>}
                    description="Our digital marketing solutions brought us targeted leads and improved our brand visibility significantly"
                />
                <OwlCarousel options={options} >
                    <SingleTestimonial5
                        itemClass="testi-item"
                        ratingImage={rating}
                        quoteImage={quote}
                        authorImage={author1}
                        Title="Sadhvi Academy"
                        // Designation="Sadhvi Academy"
                        Description="Sadhvi Software Solution's digital marketing services transformed our online presence. Their expertise in SEO and social media led to increased visibility and student enrollment. Highly recommended for exceptional results!"
                    />
                    <SingleTestimonial5
                        itemClass="testi-item"
                        ratingImage={rating}
                        quoteImage={quote}
                        authorImage={author2}
                        Title="Q&Q Solutions "
                        // Designation="Q&Q Solutions"
                        Description="Sadhvi Software Solution's digital marketing strategies brought remarkable growth to our business. Their targeted campaigns and effective lead generation boosted our sales and revenue. A trusted partner for success!"
                    />
                    <SingleTestimonial5
                        itemClass="testi-item"
                        ratingImage={rating}
                        quoteImage={quote}
                        authorImage={author3}
                        Title="Aksharam School"
                        // Designation="Aksharam School"
                        Description="Choosing Sadhvi Software Solution's 
                        for our digital marketing needs was a game-changer. 
                        Their tailored approach and engaging content strategies 
                        resulted in higher admissions and  
                        Exceptional service that exceeded expectations!"
                    />
                    <SingleTestimonial5
                        itemClass="testi-item"
                        ratingImage={rating}
                        quoteImage={quote}
                        authorImage={author4}
                        Title="Arcadia Hotel Supplies"
                        // Designation="Arcadia Hotel Supplies"
                        Description="Sadhvi Software Solution's digital
                         marketing solutions elevated our hotel's online visibility 
                         and bookings. Their expertise in SEO and online advertising 
                         delivered impressive results, increasing our revenue significantly!"
                    />
                </OwlCarousel>
            </div>

            {/* <div className="rs-project style3 modify1 case-style3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CaseStyleThree
                                caseClass="project-item"
                                caseImage={author4}
                                caseTitle="Product Engineering"
                                caseCategory="IT Technology"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CaseStyleThree
                                caseClass="project-item"
                                caseImage={author4}
                                caseTitle="Analytic Solutions"
                                caseCategory="IT Technology"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CaseStyleThree
                                caseClass="project-item"
                                caseImage={author4}
                                caseTitle="Project Design"
                                caseCategory="IT Technology"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 md-mb-30">
                            <CaseStyleThree
                                caseClass="project-item"
                                caseImage={author4}
                                caseTitle="Growth Strategies"
                                caseCategory="IT Technology"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 sm-mb-30">
                            <CaseStyleThree
                                caseClass="project-item"
                                caseImage={author4}
                                caseTitle="Platform Integration"
                                caseCategory="IT Technology"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <CaseStyleThree
                                caseClass="project-item"
                                caseImage={author4}
                                caseTitle="Innovative Interfaces"
                                caseCategory="IT Technology"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
        </React.Fragment>
	)
}

export default Testimonial;