import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';


// Breadcrumb Image
import bannerbg from '../../assets/img/breadcrumbs/prot2.jpg';

//Helmet
import { Helmet } from 'react-helmet';

const CaseStudtyStyleTwo = () => {

    return (
        <React.Fragment>
        <Helmet>
            <title> ML, NLP technologies are used in Sadhvi Software Solutions|Coimbatore </title>
            <meta name='description' content="To improve user experience and evaluate the accuracy of the recommendation."/>
            <meta name="keywords" content='machine learning,python,tensor-flow,scikit-learn,Pandas,
            Natural language processing,,deep learning,collabarative filtering,amazon products,projects,
            recommendation systems'/>
        </Helmet>
            {/* Header */}
            <Header
                parentMenu='page'
                secondParentMenu='case-studies'
                activeMenu='/case-studies-2'
            />
            {/* Header */}

            {/* SiteBreadcrumb */}
            <SiteBreadcrumb
                pageTitle="Product Recommendation System"
                pageName="Product Recommendation System "
                breadcrumbsImg={bannerbg}
            />
            {/* SiteBreadcrumb */}

            <div className='container_casestudy'>
                <p>
                    <h3>Challenge</h3>
                    Our project involved developing recommendation systems for Amazon products using two models - a popularity-based model and a collaborative filtering model. We evaluated the models to assess the accuracy of the recommendations and found that the collaborative filtering model outperformed the popularity-based model and was able to provide more accurate recommendations for the user.<br></br>
                    
                    <h3>Scope and Motivation</h3>
                    
                    This project’s goal was to improve user experience and increase customer loyalty by recommending the top-10 products for a user. Both models developed were evaluated on the accuracy of the recommendations and the overall user experience. The results showed that both models were successful in recommending relevant and popular products to users, leading to an improved user experience and increased customer loyalty.<br></br>
                      
                    <h3>Solution</h3>
                    Our project aimed to create a product recommendation system for Amazon products. We collected data on their purchase history, browsing history, ratings, and reviews to design and train a model that accurately recommended products based on the user's preferences. After the model was trained, we tested it to measure its performance and further optimized it to improve its accuracy and performance. Finally, we deployed it to the Amazon platform for customers to utilize the product recommendations.

                    <h3>Technologies and Tools </h3>

                   <strong>Technologies:</strong>Machine Learning, Natural Language Processing, Collaborative filtering, Recommendation system.<br></br>

                    <strong>Tools:</strong>Python, Tensor-Flow, scikit-learn, Pandas. 
                </p>
                        
            </div>


            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            {/* Footer */}
            <Footer />
            {/* Footer */}

            {/* SearchModal */}
            <SearchModal />
            {/* SearchModal */}
        </React.Fragment>

    );
}


export default CaseStudtyStyleTwo;