import React  from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import CounterDefault from '../../components/Elements/Counter/CounterDefault';

// import contactHereImg from '../../assets/img/new imges/contact 1.jpg';
import contactHereImg from '../../assets/img/about/contact-here.png';
import backgroundImageUrl from '../../assets/img/about/antique-telephone-wooden-desk-nostalgic-relic-generated-by-ai.jpg';
// Copy\src\assets\img\new imges\contact 1.jpg

const CallUs = () => {
    // const backgroundImageUrl = 'path/to/your/background-image.jpg'; // Replace with your actual background image path

    return (
      <div 
    //   className="rs-call-us relative pt-120 md-pt-70 md-pb-80" 
        style={{
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundSize: 'cover', // Ensures the background image covers the entire div
            backgroundPosition: 'center', // Centers the background image
            backgroundRepeat: 'no-repeat', // Prevents the background image from repeating
            width: '100%',
            height: '100%',
         
        }}
    >
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 hidden-md">
                        {/* <div className="image-part">
                            <img src={contactHereImg} alt="Contact Sadhvi Software Solutions, www.sadhvisoft.com" />
                        </div> */}
                    </div>
                    <div className="col-lg-6 pb-120 md-pb-80">
                        <div className="rs-contact-box text-center">
                            <div className="address-item mb-26">
                                <div className="address-icon">
                                    <i className="fa fa-phone"></i>
                                </div>
                            </div>
                            <SectionTitle
                                sectionClass="sec-title3 text-center"
                                subtitleClass="sub-text"
                                // subtitle="CALL US"
                                titleClass="title"
                                title="+91 90920 97333"
                                descClass="desc"
                                description="Have any idea or project in your mind call us or schedule an appointment. Our representative will reply to you shortly."
                            />
                            <div className="btn-part mt-40">
                                <Link className="readon lets-talk" to="/contact">Let's Talk</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default CallUs;