import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SectionTitle from '../../components/Common/SectionTitle';

import CaseStyleOne from '../../components/CaseStudy/CaseStyleOne';
import image2 from '../../assets/img/project/dmex/2.png';
import image3 from '../../assets/img/project/dmex/3.jpeg';
import image4 from '../../assets/img/project/dmex/4.GIF';
import image5 from '../../assets/img/project/dmex/5.GIF';
import image6 from '../../assets/img/project/dmex/6.jpeg';
import image7 from '../../assets/img/project/dmex/7.jpeg';



// Breadcrumb Image
import bannerbg from '../../assets/img/breadcrumbs/dmex.jpg';

const ProjectThree = () => {
    return (
        <React.Fragment>

        <Header
            parentMenu='Portfolio'
            secondParentMenu='case-studies'
            activeMenu='/dmex-solutions'
        />

        {/* SiteBreadcrumb */}
        <SiteBreadcrumb
        pageTitle="DMeX Solutions"
        pageName="DMeX Solutions"
        breadcrumbsImg={bannerbg}
        />
        {/* SiteBreadcrumb */}

        <SectionTitle
                    sectionClass="sec-title2 text-center mb-80 mt-80"
                    subtitleClass="sub-text style-bg"
                    // subtitle="Portfolio"
                    
        />

        <div class="container-client">
            <h1><span class="larger-word">Our</span> <span class="unique-word">Client</span></h1>
                <p>Discover our collaboration with DMeX Solutions, a company at the forefront of Industry 4.0. Learn how our website design effectively communicates their commitment to innovation and industry excellence.</p>
        </div>
        
        <div>
                <div className="image-container1">
                    <img
                        src={image2} 
                        alt="Project Image"
                        className="zoom-image"
                    />
                </div>
            </div>

            <div class="container-client">
            <h1><span class="larger-word">Client's</span> <span class="unique-word">Need</span></h1>
                <p>DMeX Solutions needed a website that aligns with the futuristic nature of their industry products. Explore how our solution met this need by creating a platform that effectively communicates their commitment to efficiency and connectivity.</p>
        </div>

        <div class="container-client">
            <h1><span class="larger-word">Our</span> <span class="unique-word">Solution</span></h1>
                <p>Our solution for DMeX Solutions involved crafting a website that effectively communicates the features and benefits of their ERP and Industry 4.0 products. The design reflects their commitment to innovation and efficiency in industry management.</p>
            </div>


            <div class="container-client">
            <h1><span class="larger-word">Tools &</span> <span class="unique-word">Technology</span></h1>
                <p>The heart of our solution for DMeX Solutions lies in WordPress, a robust and versatile platform. By leveraging WordPress, we created a website that effectively communicates the features and benefits of their Industry 4.0 products, ensuring a user-friendly and responsive experience for industry professionals.</p>
            </div>

            <div className="image-row">
                <div className="image-container custom-width">
                    <img
                        src={image4}
                        alt="Image 1"
                        className="image-website"
                    />
                </div>

                <div className="image-container custom-width">
                    <img
                        src={image5}
                        alt="Image 2"
                        className="image-website"
                    />
                </div>
            </div>

            <div className="image-container">
                    <img
                        src={image3}
                        alt="Image 2"
                        className="image-website"
                    />
            </div>

            <div className="image6-container">
                    <img
                        src={image6}
                        alt="Image 2"
                        className="image6-website"
                    />
            </div>

            <div className="image7-container">
                    <img
                        src={image7}
                        alt="Image 2"
                        className="image7-website"
                    />
            </div>


        {/* scrolltop-start */}
        <ScrollToTop />
        {/* scrolltop-end */}

        {/* Footer */}
        <Footer />
        {/* Footer */}

        {/* SearchModal */}
        <SearchModal />
        {/* SearchModal */}


    </React.Fragment>
);
}

export default ProjectThree;