import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import ProgressBar from '../../components/Elements/Progress/ProgressBar';

import aboutPart1 from '../../assets/img/about/about-part-1.png';
import aboutPart2 from '../../assets/img/about/abouthome.jpg';
import aboutPart3 from '../../assets/img/about/about-part-1-3.png';

const About = (props) => {
    return (
        <div id="rs-about" className="rs-about pt-130 pb-190 md-pt-80 md-pb-80 sm-pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="img-wrap">
                            <div className="ly1">
                                <img  src={aboutPart2} alt="Planing the best solutions for the clients in sadhvi software solutions" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 pl-40 md-pl-14 md-pt-240 sm-pt-66">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-30"
                            subtitleClass="sub-text"
                            // subtitle="About"
                            titleClass="title pb-20"
                            title="We Are Providing The Finest Work To Meet Your Business Challenges"
                            descClass="desc pr-90 md-pr-0"
                            description="Over 18 years of IT experience with the capability to serve clients of all sizes."
                        />
                        {/* Section Title End */}
                        <div className="row">
                            <div className="col-lg-10">
                                {/* Skill Bar Start */}
                                <div className="rs-skillbar style1">
                                    <ProgressBar
                                        completed={90}
                                        height={7}
                                        boxbg={"#eeeeee"}
                                        figurebg={"#f00"}
                                        ProgressBox="progress-box"
                                        ProgressFigure="progress-figure"
                                        ProgressCount="Progress-count"
                                        ProgressLabel="progress-label"
                                        label="Web Design"
                                        ProgressParent="main-div"
                                    />
                                    <ProgressBar
                                        completed={98}
                                        height={7}
                                        boxbg={"#eeeeee"}
                                        figurebg={"#f00"}
                                        ProgressBox="progress-box"
                                        ProgressFigure="progress-figure"
                                        ProgressCount="Progress-count"
                                        ProgressLabel="progress-label"
                                        label="Web Development"
                                        ProgressParent="main-div"
                                    />
                                    <ProgressBar
                                        completed={90}
                                        height={7}
                                        boxbg={"#eeeeee"}
                                        figurebg={"#f00"}
                                        ProgressBox="progress-box"
                                        ProgressFigure="progress-figure"
                                        ProgressCount="Progress-count"
                                        ProgressLabel="progress-label"
                                        label="Analytics Solutions"
                                        ProgressParent="main-div"
                                    />
                                    <ProgressBar
                                        completed={95}
                                        height={7}
                                        boxbg={"#eeeeee"}
                                        figurebg={"#f00"}
                                        ProgressBox="progress-box"
                                        ProgressFigure="progress-figure"
                                        ProgressCount="Progress-count"
                                        ProgressLabel="progress-label"
                                        label="Brand Identity Design"
                                        ProgressParent="main-div"
                                    />
                                    <ProgressBar
                                        completed={95}
                                        height={7}
                                        boxbg={"#eeeeee"}
                                        figurebg={"#f00"}
                                        ProgressBox="progress-box"
                                        ProgressFigure="progress-figure"
                                        ProgressCount="Progress-count"
                                        ProgressLabel="progress-label"
                                        label="Strategic Digital Marketing"
                                        ProgressParent="main-div"
                                    />
                                </div>
                                {/* Skill Bar End */}
                                <div className="btn-part mt-54">
                                    <Link to="/about" className="readon learn-more">Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;