import SectionTitle from '../../../components/Common/SectionTitle';
import SingleServiceSix from '../../../components/Service/SingleServiceSix';
import CaseStudy1 from '../../../components/Service/case-study1';
import CaseStudy2 from '../../../components/Service/case-study2';
import CaseStudy3 from '../../../components/Service/case-study3';
// Service Image Icons
import serviceImg1 from '../../../assets/img/service/wordpress-img/1.png';
import serviceImg2 from '../../../assets/img/service/wordpress-img/2.png';
import serviceImg3 from '../../../assets/img/service/wordpress-img/3.png';
// import layerIcon from '../../../assets/img/service/style9/layer-icon.png';
import borderShape from '../../../assets/img/service/border-shape.png';

const Service = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-110 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title6 mb-50 text-center"
                    subtitleClass="sub-text new-text"
                    // subtitle="Our Diverse Portfolio of Successful Projects"
                    titleClass="title new-title"
                    title="Showcase of our completed projects, each telling a story of success"
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        {/* <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        /> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 md-mb-30">
                        <CaseStudy1
                            itemClass="services-item"
                            serviceImg={serviceImg1}
                            Title="Aksharam School"
                            btnClass="services-btn3"
                            btnText="Learn More"
                            Text="Navigate Aksharam, a digital learning hub for schools, fostering education innovation and seamless communication between educators."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 md-mb-30">
                        <CaseStudy2
                            itemClass="services-item"
                            serviceImg={serviceImg2}
                            Title="Arcadia Hotel Supplies"
                            btnClass="services-btn3"
                            btnText="Learn More"
                            Text="Explore Arcadia, your destination for top-quality hotel supplies, enhancing guest experiences with style and functionality."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <CaseStudy3
                            itemClass="services-item"
                            serviceImg={serviceImg3}
                            Title="DMeX Solutions"
                            btnClass="services-btn3"
                            btnText="Learn More"
                            Text="Experience the future of industry management with Dmex, boosting efficiency and connectivity in the era of Industry 4.0."
                        />
                    </div>
                </div>
                <div className="services-border pt-120 md-pt-70">
                    <img 
                        src={borderShape} 
                        alt="Images" 
                    />
                </div>
            </div>
        </div>
    );
}

export default Service;