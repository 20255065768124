import React from 'react';
import Header from '../../../components/Layout/Header';
import Footer from '../../../components/Layout/Footer';
import SearchModal from '../../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import BannerWordpress from '../../../components/Banner/BannerWordpress';
import About from './AboutSection';
import ServiceTwo from './ServiceSectionTwo';
import Service from './ServiceSection';
// Breadcrumbs Image
import breadcrumbsImg from '../../../assets/img/breadcrumbs/2.jpg';
import WordPressDevelopmentContent from '../WordpressDevelopmentContent';


const WordpressDevelopment = () => {
    return (
        <React.Fragment>
            {/* <Header
                parentMenu='service'
                activeMenu='/WordPress Development'
            /> */}
            {/* Banner-start */}
            <BannerWordpress />
            {/* Banner-end */}
            
            {/* About-start */}
            <About />
            {/* About-End */}

            <ServiceTwo />

            < Service/>

            <WordPressDevelopmentContent />
            
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default WordpressDevelopment;