import React from 'react';
import { Helmet } from 'react-helmet';

const BannerDefault = () => {

    return (
        <React.Fragment>
        
        <Helmet>
        <title> Web design company in india
                    | Web design company in coimbatore
                    | Web design agency in india
                    | Web design agency in coimbatore
                    | Web design services in coimbatore
                    |top 10 Web design services in coimbatore
                    |top 10 Web design services in india
                    |top 10 Web design agency in coimbatore
                    |top 10 Web design agency in India
                    |top 10 digital marketing service in coimbatore
                    |top 10 digital marketing service in india
                    |top 10 digital marketing agency in coimbatore
                    |top 10 digital marketing agency in india
                    |digital marketing service in coimbatore
                    |digital marketing service in india
                    |digital marketing agency in india
                    |digital marketing agency in coimbatore
                </title>
            {/* <meta name="title" content="Sadhvi Software Solutions Coimbatore"></meta> */}
            <meta name="description" content="Our trendsetting solutions are patterned to meet your specific needs. You can reach your goals faster than ever before!&quot"></meta>
            <meta name="keywords" content="best software startup company, software company, website development, website design, software company in coimbatore,software company near me,python,MongoDB,
            reactjs,nodejs,GPT3,Top 10 startup company,clients,pandas,nlp,machine learning,artificial intelligence"></meta>
        </Helmet>
            {/* <!-- banner section start --> */}
            <div className="rs-banner style1">
            
                <div className="banner-height pt-100 pb-100 md-pt-80 md-pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 pt-8 pr-140 md-pt-0 md-mb-60 md-pr-14">
                                <div className="banner-content">
                                    <h1 className="bnr-title" data-animation="fadeInLeft" data-delay=".3s">Sadhvi Software Solutions</h1>
                                    <p  className="bnr-desc" data-animation="fadeInLeft" data-delay=".5s">
                                    Leading software development company. We specialize in web design, branding, and digital marketing with expert IT services.
                                         </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}
        
        </React.Fragment>
    );
}

export default BannerDefault;