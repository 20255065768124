import React from "react";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

import SectionTitle from '../../../components/Common/SectionTitle';
// Faq Image
import faqImage from '../../../assets/img/about/faq-question-information-helpdesk-graphic-word.jpg';

const FaqMain = () => {

    return (
        <React.Fragment>
            {/* Faq Part Start */}
            <div className="rs-faq gray-bg pt-50 md-pb-394 pb-150 md-pt-90 md-pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pr-40 md-pr-14">
                            <div className="images-part">
                                <img
                                    src={faqImage}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <SectionTitle
                                sectionClass="sec-title2 mb-44"
                                subtitleClass="sub-text style-bg"
                                // subtitle="Faqs"
                                titleClass="title title6"
                                title="Do You Have Any Questions?"
                            />
                            <Accordion className="accodion-style1" preExpanded={'b'}>
                                <AccordionItem uuid="a">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        What is digital marketing?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Digital marketing refers to the promotion of products or services using digital channels such as websites, search engines, social media, email, and more.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="b">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        What digital marketing services do you offer?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>A: We offer a wide range of digital marketing services, including search engine optimization (SEO), Search Engine Marketing (SEM) advertising, social media marketing (SMM), Content Marketing, Email Marketing, and WhatsApp marketing.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="c">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How can digital marketing benefit my business?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Digital marketing can significantly benefit your business by increasing online visibility, driving targeted traffic to your website, generating leads, improving brand awareness, and ultimately boosting sales and revenue.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="d">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        Is digital marketing suitable for small businesses?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Yes, digital marketing is highly beneficial for small businesses. It offers cost-effective marketing solutions, enables precise targeting, and provides opportunities for competing with larger competitors on a level playing field.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="e">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                        How can I started with your digital marketing services?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Getting started is simple. Just contact our team, and we'll schedule a consultation to discuss your business needs, goals, and the best digital marketing strategies to help you achieve success.``````</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            {/* Faq Part End */}

        </React.Fragment>
    )
}

export default FaqMain;





