import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import CaseStyleSix from '../../components/CaseStudy/CaseStyleSix';
import SectionTitle from '../../components/Common/SectionTitle';
// Breadcrumb Image
import bannerbg from '../../assets/img/breadcrumbs/3.jpg';

// Case Images
import image1 from '../../assets/img/project/aksharam/aksharam.png';
import image2 from '../../assets/img/project/arcadia/arcadia.png';
import image3 from '../../assets/img/project/dmex/dmex.png';

const CaseStudtyStyleSix = () => {

    return (
        <React.Fragment>
            

            {/* CaseStudy Start */}
            <div className="rs-project style8 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-30 ">
                            <CaseStyleSix
                                caseClass="project-item"
                                caseImage={image1}
                                caseTitle="Enhancing Education"
                                caseCategory="Innovative Learning Spaces"
                                description=""
                            />
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-5 pl-10 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg"
                                    // subtitle="Wordpress Development"
                                    titleClass="title pb-36"
                                    title="Aksharam International School"
                                    descClass="desc pb-34"
                                    description="Explore our Aksharam project—a digital space where learning meets innovation. Witness seamless communication and educational richness. Click here to view the project and experience the future of education with Sadhvi Software Solutions."
                                    />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/Portfolio/project-one">Explore Aksharam</Link>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="rs-project style8 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        
                        
                        <div className="col-lg-5 pl-10 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg"
                                    // subtitle="Wordpress Development"
                                    titleClass="title pb-36"
                                    title="Arcadia Hotel Supplies"
                                    descClass="desc pb-34"
                                    description="Indulge in luxury at Arcadia Hotel Supplies. Click here to view our website, showcasing elegance and top-quality hospitality supplies. Discover sophistication redefined with Sadhvi Software Solutions."
                                    />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/Portfolio/project-two">Dive into Arcadia</Link>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-6 col-md-6 mb-30">
                            <CaseStyleSix
                                caseClass="project-item"
                                caseImage={image2}
                                caseTitle="Elegant Hospitality Solutions"
                                caseCategory="Luxury Redefined"
                                description=""
                            />
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="rs-project style8 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-30">
                            <CaseStyleSix
                                caseClass="project-item"
                                caseImage={image3}
                                caseTitle="Efficiency and Connectivity"
                                caseCategory="Industry 4.0 Prowess"
                                description=""
                            />
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-5 pl-10 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg"
                                    // subtitle="Wordpress Development"
                                    titleClass="title pb-36"
                                    title="DMeX Solutions"
                                    descClass="desc pb-34"
                                    description="Step into the future of industry management with DMeX Solutions. Click here to explore our website, highlighting ERP and Industry 4.0 products. Experience efficiency and connectivity in the Industry 4.0 era with Sadhvi Software Solutions."
                                    />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/Portfolio/project-three">Discover DMeX</Link>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* CaseStudy End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            {/* Footer */}
            {/* <Footer /> */}
            {/* Footer */}

            {/* SearchModal */}
            <SearchModal />
            {/* SearchModal */}
        </React.Fragment>

    );
}


export default CaseStudtyStyleSix;