import SectionTitle from '../../../components/Common/SectionTitle';
import SingleServiceFlip from '../../../components/Service/SingleServiceFlip';
import SingleServiceFlipTwo from '../../../components/Service/SingleServiceFlipTwo';
import SingleServiceFlipThree from '../../../components/Service/SingleServiceFlipThree';
import SingleServiceFlipFour from '../../../components/Service/SingleServiceFlipFour';
import SingleServiceFlipFive from '../../../components/Service/SingleServiceFlipFive';
import SingleServiceFlipSix from '../../../components/Service/SingleServiceFlipSix'; //
import SingleServiceFlipSeven from '../../../components/Service/SingleServiceFlipSeven'; 
import serviceIcon1 from '../../../assets/img/service/main-home/1.png';
import serviceIcon2 from '../../../assets/img/service/main-home/2.png';
import serviceIcon3 from '../../../assets/img/service/main-home/3.png';
import serviceIcon4 from '../../../assets/img/service/main-home/4.png';
import serviceIcon5 from '../../../assets/img/service/main-home/content_15202837.png';
import serviceIcon6 from '../../../assets/img/service/main-home/report_11176291.png';
import serviceIcon7 from '../../../assets/img/service/main-home/6.png';

// newcode
// import serviceIcon7 from '../../../assets/img/service/main-home/ppc.png';
// import serviceIcon8 from '../../../assets/img/service/main-home/influencermk.png';
// import serviceIcon9 from '../../../assets/img/service/main-home/sms_2451015.png';
// import serviceIcon10 from '../../../assets/img/service/main-home/analytic_and_report.png';
// import serviceIcon11 from '../../../assets/img/service/main-home/smm.png';

import shapeImg from '../../../assets/img/service/s2.png';

const ServiceFlip = () => {

    return (
        <div id="rs-service" className="rs-services main-home style2 pt-120 pb-94 md-pt-80 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                    subtitleClass="sub-text style-bg"
                    // subtitle="Services"
                    titleClass="title"
                    title="We Are Offering All Kinds of Digital Marketing Services"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon1}
                            serviceURL="software-development"
                            Title="Search Engine Optimization"
                            TextDesc="Maximize online presence, drive growth. Optimize website for search engines. Get ahead with expert SEO content."
                            ButtonClass="readon view-more"
                            ButtonText="Learn More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlipTwo
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon2}
                            serviceURL="web-development"
                            Title="Search Engine Marketing"
                            TextDesc="Optimize content, target keywords, run ad campaigns to boost visibility, drive quality traffic, and maximize conversions."
                            ButtonClass="readon view-more"
                            ButtonText="Learn More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlipThree
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon3}
                            serviceURL="analytic-solutions"
                            Title="Social Media Marketing"
                            TextDesc="Drive engagement, build brand awareness, increase traffic. Elevate online presence and grow business with effective strategies."
                            ButtonClass="readon view-more"
                            ButtonText="Learn More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlipFour
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon4}
                            serviceURL="cloud-and-devops"
                            Title="Content Marketing"
                            TextDesc="Boost strategy with impactful Content Marketing. Craft compelling content,
                             engage your audience, establish thought leadership."
                            ButtonClass="readon view-more"
                            ButtonText="Learn More"
                        />
                    </div>

                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlipFive
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon5}
                            serviceURL="product-design"
                            Title="Social Media Optimization"
                            TextDesc="Expand your reach and enhance engagement with targeted Social Media Optimization. 
                            Use tactics to drive conversions and build your brand."
                            ButtonClass="readon view-more"
                            ButtonText="Learn More"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlipSeven
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon6}
                            serviceURL="cloud-and-devops"
                            Title="Analytics And Report"
                            TextDesc="Analyze performance, track metrics, and generate reports.
                             Make data-driven decisions to enhance engagement and optimize conversions."
                            ButtonClass="readon view-more"
                            ButtonText="Learn More"
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 mb-26 mx-auto">
                        <SingleServiceFlipSix
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon7}
                            serviceURL="data-center"
                            Title="WhatsApp Marketing"
                            TextDesc="Connect, personalize messages, promote products. Drive engagement, conversions. Leverage WhatsApp's popularity for website success."
                            ButtonClass="readon view-more"
                            ButtonText="Learn More"
                        />
                    </div>
                </div>
            </div>
            <div className="shape-animation">
                <div className="shape-part">
                    {/* <img className="dance" src={shapeImg} alt="images" /> */}
                </div>
            </div>
        </div>
    );
}
export default ServiceFlip;