import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/img/logo/sadhvi-logo.jpg';

const TopHeader = () => {

    return (
        <div className="topbar-area hidden-md">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="logo-part">
                            <Link to="/"><img src={Logo} alt="Logo"/></Link>
                        </div>
                    </div>
                    <div className="col-lg-9 text-right">
                        <ul className="rs-contact-info">
                            <li className="contact-part">
                                <i className="flaticon-location"></i>
                                <span className="contact-info">
                                    
                                    No.5, SK Arcade,<br></br>Saffrons Plumeria, Vellakinar,<br></br>Coimbatore, Tamil Nadu,<br></br> India - 641029.
                                </span>
                            </li>
                            <li className="contact-part">
                                <i className="flaticon-email"></i>
                                <span className="contact-info">
                                    <a href="mailto:support@rstheme.com"> info@sadhvisoft.com</a>
                                </span>
                            </li>
                            <li className="contact-part">
                                <i className="flaticon-call"></i>
                                <span className="contact-info">
                                    +91 90920 97333
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopHeader;