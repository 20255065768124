import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SectionTitle from '../../components/Common/SectionTitle';

import CaseStyleOne from '../../components/CaseStudy/CaseStyleOne';
import image1 from '../../assets/img/project/aksharam/1.JPG';
import image2 from '../../assets/img/project/aksharam/2.png';
import image3 from '../../assets/img/project/aksharam/3.jpeg';
import image4 from '../../assets/img/project/aksharam/4.JPG';
import image5 from '../../assets/img/project/aksharam/5.JPG';
import image6 from '../../assets/img/project/aksharam/6.jpeg';
import image7 from '../../assets/img/project/aksharam/7.jpeg';


// Breadcrumb Image
import bannerbg from '../../assets/img/breadcrumbs/aksharam.jpg';

const ProjectOne = () => {
    return (
        <React.Fragment>
            <Header
            parentMenu='Portfolio'
            secondParentMenu='case-studies'
            activeMenu='/aksharam-school'
             />

             {/* SiteBreadcrumb */}
            <SiteBreadcrumb
            pageTitle="Aksharam School"
            pageName="Aksharam School"
            breadcrumbsImg={bannerbg}
            />
            {/* SiteBreadcrumb */}

            <SectionTitle
                        sectionClass="sec-title2 text-center mb-80 mt-80"
                        subtitleClass="sub-text style-bg"
                        // subtitle="Portfolio"
                        titleClass="title title2"
                                    
            />
            
            <div class="container-client">
            <h1><span class="larger-word">Our</span> <span class="unique-word">Client</span></h1>
                <p>Aksharam International School entrusted us to create an immersive online learning environment. Explore how we showcased their school, faculty, students, and activities on a platform that fosters educational innovation and communication.</p>
            </div>

            <div>
                <div className="image-container1">
                    <img
                        src={image2} 
                        alt="Project Image"
                        className="zoom-image"
                    />
                </div>
            </div>

            <div class="container-client">
            <h1><span class="larger-word">Client's</span> <span class="unique-word">Need</span></h1>
                <p>Aksharam International School needed a digital platform that unifies education stakeholders. Discover how our solution addressed their need for an engaging and informative website, highlighting the richness of their educational ecosystem.</p>
            </div>

            <div class="container-client">
            <h1><span class="larger-word">Our</span> <span class="unique-word">Solution</span></h1>
                <p>Explore our comprehensive solution that transformed Aksharam International School's online presence into a dynamic learning hub. From seamless communication to showcasing activities and infrastructure, our website reflects their commitment to education.</p>
            </div>

            <div class="container-client">
            <h1><span class="larger-word">Tools &</span> <span class="unique-word">Technology</span></h1>
                <p>Our expertise in WordPress development played a pivotal role in creating a dynamic and responsive website for Aksharam International School. Leveraging the power of WordPress, we ensured seamless communication and a user-friendly interface, allowing educators, students, and parents to engage effortlessly.</p>
            </div>

            <div className="image-row">
                <div className="image-container custom-width">
                    <img
                        src={image4}
                        alt="Image 1"
                        className="image-website"
                    />
                </div>

                <div className="image-container custom-width">
                    <img
                        src={image5}
                        alt="Image 2"
                        className="image-website"
                    />
                </div>
            </div>

            <div className="image-container">
                    <img
                        src={image3}
                        alt="Image 2"
                        className="image-website"
                    />
            </div>

            <div className="image6-container">
                    <img
                        src={image6}
                        alt="Image 2"
                        className="image6-website"
                    />
            </div>

            <div className="image7-container">
                    <img
                        src={image7}
                        alt="Image 2"
                        className="image7-website"
                    />
            </div>

            

        

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            {/* Footer */}
            <Footer />
            {/* Footer */}

            {/* SearchModal */}
            <SearchModal />
            {/* SearchModal */}


        </React.Fragment>
    );
}

export default ProjectOne;