import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = (props) => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        debugger
        emailjs.sendForm('service_4v7lr9m', 'template_nxozj9s', form.current, 'mgpM71RkhWwbKAhOY')
        .then((result) => {
            debugger
            console.log(result.text);
            alert("Message sent")
            e.target.reset();
        }, (error) => {
            console.log(error.text);
        });
    };

    const { submitBtnClass } = props;
    return (
        <form  ref={form} onSubmit={sendEmail}>
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="name" 
                    name="name" placeholder="Name" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="email"
                     name="email" placeholder="E-Mail" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="phone" 
                    name="phone" placeholder="Phone Number" required />
                </div>


                <div className="col-12 mb-30">
                    <textarea className="from-control" id="message" name="message" 
                    placeholder="Your message Here" required></textarea>
                </div>
            </div>
            <div className="btn-part">
                <button className={submitBtnClass ? submitBtnClass : 'readon learn-more submit'} 
                type="submit">Submit Now</button>
            </div>
        </form>
    );

}

export default ContactForm;