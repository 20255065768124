import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ContactMain from '../../src/pages/service/DigitalMarketing/ContactMain';

// Breadcrumbs Image
import breadcrumbsImg from '../../assets/img/breadcrumbs/3.jpg';
import AnalyticSolutionContent from './AnalyticSolutionContent';


const AnalyticSolutions = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='service'
                activeMenu='/analytics-solutions'
            />
            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                breadcrumbsImg={breadcrumbsImg}
                pageTitle='Web Development'
                pageName='Web Development'
            />
            {/* breadcrumb-area-end */}
            
            <AnalyticSolutionContent />
<ContactMain/>
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default AnalyticSolutions;