import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
// import SingleService from '../../components/Service/SingleService';
import SingleServicewordpress from '../../components/Service/SingleServicewordpress';
import SingleServicewoocom from '../../components/Service/SingleServicewoocom';
import SingleServiceews from '../../components/Service/SingleService-ews ';
import SingleServicebws from '../../components/Service/SingleServicebws';
import SingleServicereact from '../../components/Service/SingleServicereact';


import ProgressBar from '../../components/Elements/Progress/ProgressBar';
import ScrollToTop from '../../components/Common/ScrollTop';

// Service Image
// import serviceIcon1 from '../../assets/img/service/style7/1.png';
// import serviceIcon2 from '../../assets/img/service/style7/2.png';
// import serviceIcon3 from '../../assets/img/service/style7/3.png';
import serviceIcon001 from '../../assets/img/icon/react native (1).png';
import serviceIcon002 from '../../assets/img/choose/icons/woocomerce.png';
import serviceIcon003 from '../../assets/img/choose/icons/businesswebsite.png';
import serviceIcon004 from '../../assets/img/choose/icons/ecommercewebsite.png';
import serviceIcon005 from '../../assets/img/about/wordpress.png';
import aboutImg1 from '../../assets/img/about/web developement.jpg';
//  Copy\src\src\assets\img\choose\icons\businesswebsite.png

// FlowChart Image
import Flowchart from '../../assets/img/flowchart/Analytics_solution.png'
import '../../assets/scss/App.scss'


// Choose Us Image
import whyChooseUsImg from '../../assets/img/choose/front-view-laptop-office-workspace-with-lamp-notebook.jpg';
// C:\Users\HP\Desktop\SadhviSoft - Copy\SadhviSoft - Copy\src\assets\img\choose\top-view-office-desk-with-calculator-growth-chart.jpg

//helmet
import { Helmet } from 'react-helmet';

const AnalyticSolutionContent = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title> web development company in india
                    | web development company in coimbatore
                    | web development agency in india
                    | weeb development agency in coimbatore
                    | web development services in coimbatore
                    |top 10 webdevelopment services in coimbatore
                    |top 10 web development services in india
                    |top 10 web development agency in coimbatore
                    |top 10 web development agency in India</title>
                <meta name='description' content="Business strategies and technical expertise with our range of services 
        like data,integration ,data visualization,predictive analysis,big data,cloud computing,ML algorithms,
        data driven decisions."/>
                <meta name="keywords" content='data science and analytics,web scrapping,datalake,data migration,data privacy,security,data collection,ML algorithms,Cloud computing,python,bigdata,
        predictive analysis,data visualization,facebook,twitter,instagram,linkedin,google ads'/>
            </Helmet>


            {/* About Section Start */}
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                // subtitle="Web Developement"
                                titleClass="title"
                                title="Empowering Your Web Presence with Web Development"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="We solve your web development challenges with strategic insights and technical expertise. Our services include Website Design, Frontend and Backend Development, UX/UI Optimization, E-commerce Solutions, and CMS Integration. We use technologies like React, Angular, Node.js, and WordPress to create solutions that fit your business goals. Our focus is to strengthen your online presence, improve user experiences, and ensure your website is scalable and high-performing for long-term growth."
                            />
                            {/* Section Title End */}
                        </div>
                        <div className="col-lg-6">
                            <div className="images">
                                <img src={aboutImg1} alt="Providing the best analytic solutions to the clients in sadhvi software solutions, coimbatore." />
                            </div>
                        </div>
                    </div>
                    <div className="btn-part mt-46">
                        <Link to="/contact">
                            <a className="readon learn-more">Contact Us</a>
                        </Link>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    {/* How we do Start */}
                    {/* <SectionTitle
                        titleClass="title"
                        title="How We Do"
                        sectionClass="sec-title4"
                        descClass="desc desc-big"
                        description={(
                            <p>
                            <strong>WordPress Development:</strong> Crafting customized and dynamic websites with WordPress, renowned for its flexibility and ease of use.<br /><br />
                        
                            <strong>WooCommerce Development:</strong> Creating robust and scalable e-commerce solutions with WooCommerce, designed to power your online store with advanced features.<br /><br />
                        
                            <strong>Business Website Development:</strong> Building professional and impactful business websites that enhance your digital presence and drive engagement.<br /><br />
                        
                            <strong>E-Commerce Website Development:</strong> Designing high-converting e-commerce sites with the latest technologies to provide an exceptional shopping experience.<br /><br />
                        
                            <strong>React.js Web Development:</strong> Developing interactive and high-performance web applications using React.js, known for its component-based architecture and efficient rendering.<br /><br />
                        </p>
                        
                        )}
                    /> */}


                    {/* How we do End */}

                    {/* Flow Chart Start*/}
                    {/* <div className='container_flowchart'>
                        <div className="image_flowchart">
                            <img src={Flowchart} alt="" />
                        </div>
                    </div> */}
                    {/* Flow Chart Start*/}
                    {/* <div className="rs-services style5 pt-10 md-pt-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 md-mb-30">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon1}
                                        Title="Expert People"
                                        Text="With experts, we stay ahead of the competition and better assist our clients. "
                                    />
                                </div>
                                <div className="col-lg-4 md-mb-30">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon2}
                                        Title="First Growing Process"
                                        Text="
                                        With new emerging technologies, we develop strategic partnerships in a challenging manner. "
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <SingleService
                                        itemClass="services-item"
                                        serviceImage={serviceIcon3}
                                        Title="Creative Ideas"
                                        Text="Our creative ideas allow us to view and solve problems more openly with innovation. "
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* About Section End */}
            <div id="rs-service" className="rs-services main-home gray-bg pt-110 pb-120 md-pt-70 md-pb-70">
                <div className="container">

                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text"
                        // subtitle="Services"
                        titleClass="title"
                        title="Unlocking Business Potential through Web Development Solutions"
                        effectClass="heading-line"
                    />
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-30"style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleServicewordpress
                                itemClass="services-item"
                                serviceImage={serviceIcon005}
                                serviceURL="web-design & Web Development"
                                Title="WordPress"
                                Text="WordPress Transform your website with custom WordPress solutions designed to streamline operations and fuel growth."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 mb-30"style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleServicewoocom
                                itemClass="services-item"
                                serviceImage={serviceIcon002}
                                serviceURL="analytics-solution"
                                Title="WooCommerce"
                                Text="WooCommerce Elevate your store with scalable WooCommerce solutions that enhance efficiency and drive sales."
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 mb-30"style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleServicebws
                                itemClass="services-item"
                                serviceImage={serviceIcon003}
                                serviceURL="web-development"
                                Title="Business Website"
                                Text="Business Website Empower your digital presence with innovative business website solutions. Let’s create something extraordinary together!"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 sm-mb-30"style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleServiceews
                                itemClass="services-item"
                                serviceImage={serviceIcon004}
                                serviceURL="machine-learning"
                                Title="E-Commerce WebSite"
                                Text="E-Commerce Website Revamp your online store with cutting-edge e-commerce solutions. Let’s craft an exceptional shopping experience! "
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 offset-lg-3 offset-md-3"style={{
                                    display: 'inline-block',
                                    transition: 'transform 0.3s ease-in-out',
                                }}
                                    onMouseEnter={(e) => e.currentTarget.style.transform = 'rotate(10deg)'}
                                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}>
                            <SingleServicereact
                                itemClass="services-item"
                                serviceImage={serviceIcon001}
                                serviceURL="machine-learning"
                                Title="React js website development"
                                Text="React.js Website Development Boost your online presence with advanced React.js solutions. Let’s build an exceptional web experience together!"
                            />
                        </div>          
                        {/* <div className="col-lg-6 col-md-6 offset-lg-3 offset-md-3">
                        <SingleService
                            itemClass="services-item"
                            // serviceImage={serviceIcon6}
                            serviceURL="digital-marketing"
                            Title="Strategic Digital Marketing"
                            Text="Advertise, Optimize and Analyze it for you to reach your desired target markets and drive more sales."
                        />
                    </div> */}
                    </div>
                </div>
            </div>
            {/* Why Choose US Section Start */}
            <div className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pr-46 md-pr-15 md-mb-50">
                            <div className="choose-img">
                                <img className="main" src={whyChooseUsImg} alt="Providing an excellent analytic solutions with appealling model. Startup software company in coimbatore." />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text choose"
                                subtitle="Why Choose Us"
                                titleClass="title pb-20"
                                title="We Are Increasing Business Success With Technology"
                                descClass="desc"
                                description="Sadhvi Software Solutions offers a Services that includes Data Exploration, Data Visualization, and Predictive Analytics. Our experienced team of Data Scientists and Analysts ensure reliable insights to meet your business goals."
                            />
                            {/* Section Title End */}
                            <div className="row">
                                <div className="col-lg-10">
                                    {/* Skill Bar Start */}
                                    <div className="rs-skillbar style1 modify1">
                                        <div className="rs-skillbar style1">
                                            <ProgressBar
                                                completed={90}
                                                height={7}
                                                boxbg={"#eeeeee"}
                                                figurebg={"#f00"}
                                                ProgressBox="progress-box"
                                                ProgressFigure="progress-figure"
                                                ProgressCount="Progress-count"
                                                ProgressLabel="progress-label"
                                                label="Web Design"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={98}
                                                height={7}
                                                boxbg={"#eeeeee"}
                                                figurebg={"#f00"}
                                                ProgressBox="progress-box"
                                                ProgressFigure="progress-figure"
                                                ProgressCount="Progress-count"
                                                ProgressLabel="progress-label"
                                                label="Web Development"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={90}
                                                height={7}
                                                boxbg={"#eeeeee"}
                                                figurebg={"#f00"}
                                                ProgressBox="progress-box"
                                                ProgressFigure="progress-figure"
                                                ProgressCount="Progress-count"
                                                ProgressLabel="progress-label"
                                                label="Analytic Solutions"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={95}
                                                height={7}
                                                boxbg={"#eeeeee"}
                                                figurebg={"#f00"}
                                                ProgressBox="progress-box"
                                                ProgressFigure="progress-figure"
                                                ProgressCount="Progress-count"
                                                ProgressLabel="progress-label"
                                                label="Brand Identity Design"
                                                ProgressParent="main-div"
                                            />
                                            <ProgressBar
                                                completed={95}
                                                height={7}
                                                boxbg={"#eeeeee"}
                                                figurebg={"#f00"}
                                                ProgressBox="progress-box"
                                                ProgressFigure="progress-figure"
                                                ProgressCount="Progress-count"
                                                ProgressLabel="progress-label"
                                                label="Strategic Digital Marketing"
                                                ProgressParent="main-div"
                                            />
                                        </div>
                                    </div>
                                    {/* Skill Bar End */}
                                    <div className="btn-part mt-46">
                                        <Link to="/contact">
                                            <a className="readon learn-more">Contact Us</a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Why Choose US Section End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}

export default AnalyticSolutionContent;