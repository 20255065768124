import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';


const BannerDigitalMarketing = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);


    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-banner style4">  
                <ModalVideo channel='youtube' isOpen={isOpen} videoId='FMvA5fyZ338' onClose={() => { openModal(); }} />              
                <div className="container">
                    <div className="banner-content">
                        {/* <span className="sub-title">Digital Marketing </span> */}
                       <h1 className="title">Empower Your Brand's Online Journey with Innovative Marketing Strategies!</h1>
                        <p className="desc">
                        Drive Growth, Increase Visibility, and Reach Your Target Audience Effectively
                        </p>
                        <ul className="banner-btn">
                            <li><Link className="readon discover" to="/contact">Get Started Today</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}
            
        </React.Fragment>
    );
}

export default BannerDigitalMarketing;