import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import BrandTwo from '../../components/Common/Brand/BrandTwo'


import bannerimg from '../../assets/img/banner/web-design-programming-homepage-website-concept_960782-41032.png';
import bnrAnimImg from '../../assets/img/banner/b4-dot-6.png';

const BannerWordpress = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <React.Fragment>

            {/* <!-- banner section start --> */}
            {/* <div className="rs-banner style3 modify2">
                
                <div className="container">
                    <div className="row y-bottom">
                        <div className="col-lg-6 md-mt-50 order-last">
                            <div className="banner-img">
                                <img
                                    src={bannerimg}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="banner-content mb-120">
                           
                                <h1 className="title">WordPress Development</h1>
                                <p className="desc">Achieve your online presence with our WordPress expertise and bring your vision to life</p>
                                <ul className="banner-btn">
                                    <li><Link className="readon started" to="/contact">Let's Begin</Link></li>
                                    
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="rs-animation">
                    <img className="dance3" src={bnrAnimImg} alt="images" />
                </div>
            </div> */}
            {/* <!-- banner section end --> */}

        </React.Fragment>
    );
}

export default BannerWordpress;