import React from 'react';
import SectionTitle from '../Common/SectionTitle';

const ContactInfo = (props) => {
    const { contactBoxClass } = props;
    return (
        <div className={contactBoxClass ? contactBoxClass : 'contact-box'}>
            <SectionTitle
                sectionClass="sec-title mb-44"
                subtitleClass="sub-text new-text white-color"
                // subtitle="Let's Talk"
                titleClass="title white-color"
                title="Speak With Expert Engineers."
            />
            <div className="address-box mb-24">
                <div className="address-icon">
                    <i className="fa fa-phone"></i>
                </div>
                <div className="address-text">
                    
                    <a href="tel:123222-8888">+91 90920 97333</a>
                </div>
            </div>
            <div className="address-box mb-24">
                <div className="address-icon">
                    <i className="fa fa-home"></i>
                </div>
                <div className="address-text">
                   
                    <a href="mailto:info@sadhvisoft.com">info@sadhvisoft.com</a>
                </div>
            </div>
            <div className="address-box">
                <div className="address-icon">
                    <i className="fa fa-map-marker"></i>
                </div>
                <div className="address-text">

                    <div className="desc">No.5, SK Arcade,<br></br>Saffrons Plumeria, Vellakinar,<br></br>Coimbatore, Tamil Nadu,<br></br> India - 641029.</div>
                </div>
            </div>
        </div>
    );

}

export default ContactInfo;