import { Link } from 'react-router-dom';

import bgImg from '../../../../assets/img/bg/choose-bg.jpg';

const WhyChooseUs = () => {
    const bgStyle = {
        backgroundImage:`url(${bgImg})`
    }


    return (
        <div className="rs-why-choose style3 pt-120 pb-120 md-pt-74 md-pb-80" style={bgStyle}>
            <div className="container relative">
                <div className="sec">
                    <h1 className="title white-color">Why Should You Use SMM? Unlocking the Benefits</h1>
                </div>
                <br></br>
                <div className="row align-items-center">
                    <div className="col-lg-12 md-pb-30">
                        <div className="features-content">
                            <ul className="features-list">
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>
                                    Enhanced brand visibility and recognition among your target audience, leading to increased brand awareness and recall</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Direct engagement and communication with customers, fostering stronger relationships and customer loyalty</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i><span>
                                    Increased website traffic and lead generation potential, resulting in higher conversion rates and business growth</span>
                                </li>
                                <li><i className="fa fa-check"></i>
                                <span>Cost-effective marketing compared to traditional methods, allowing you to reach a wider audience at a lower cost</span></li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Precise targeting capabilities, enabling you to reach specific demographics, interests, and locations</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i><span>
                                    Real-time feedback and insights, helping you understand your audience better and refine your marketing strategies</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Amplification of content through social sharing, increasing your brand's reach and potential for viral growth</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Competitive advantage by staying ahead of industry trends and leveraging social media platforms to engage with your audience</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>Integration with other marketing channels for a holistic and cohesive marketing approach</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;