import React from 'react';
// import { Link } from 'react-router-dom';
// import SectionTitle from '../../../components/Common/SectionTitle';
// import SingleService from '../../../components/Service/SingleService';

// import ProgressBar from '../../../components/Elements/Progress/ProgressBar';
import ScrollToTop from '../../../components/Common/ScrollTop';

// Service Image
// import serviceIcon1 from '../../../assets/img/service/style7/1.png';
// import serviceIcon2 from '../../../assets/img/service/style7/2.png';
// import serviceIcon3 from '../../../assets/img/service/style7/3.png';

// import aboutImg1 from '../../../assets/img/about/1.jpg';

// FlowChart Image
// import Flowchart from '../../../assets/img/flowchart/Cloud_and_devops.png'
import '../../../assets/scss/App.scss'


// Choose Us Image
import whyChooseUsImg from '../../../assets/img/choose/3.png';

//helmet
import { Helmet } from 'react-helmet';

const BrandIdentity = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Leading Brand Identity Design Company in India
                    |Brand Identity Design Company in Coimbatore
                    |Innovative Brand Identity Design Agency in India
                    |Trusted Brand Identity Design Agency in Coimbatore
                    |Top Brand Identity Design Services in Coimbatore
                    |Top 10 Brand Identity Design Services in Coimbatore
                    |Top 10 Brand Identity Design Services in India
                    |Top 10 Brand Identity Design Agency in Coimbatore
                    |Top 10 Brand Identity Design Agency in India</title>
                <meta name='description' content="Provides an excellent works in Clouds&DevOps using Azure,ci/cp pipeline and deploy them.
        This is one of the most innovative and different way of using the website with latest technologies and tools."/>
                <meta name="keywords" content='Azure Clouds&DevOps,ci/cp pipeline ,facebook,twitter,instagram,linkedin,google ads' />
            </Helmet>
            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}

export default BrandIdentity;