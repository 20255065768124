import SectionTitle from '../../../../components/Common/SectionTitle';
import SingleServiceBox from '../../../../components/Service/SingleServiceBox';

// Service Icons
import img1 from '../../../../assets/img/technology/style9/1.png';
import img2 from '../../../../assets/img/technology/style9/2.png';
import img3 from '../../../../assets/img/technology/style9/3.png';
import img4 from '../../../../assets/img/technology/style9/4.png';
import img5 from '../../../../assets/img/technology/style9/5.png';
import img6 from '../../../../assets/img/technology/style9/6.png';
import img7 from '../../../../assets/img/technology/style9/7.png';
import img8 from '../../../../assets/img/technology/style9/8.png';
import img9 from '../../../../assets/img/technology/style9/9.png';
import img10 from '../../../../assets/img/technology/style9/10.png';

const Service = () => {

    return (
        <div className="rs-services style8 pt-120 pb-114 md-pt-70 md-pb-70" style={{  paddingtop: '40px !important' }}>
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text gold-color"
                    // subtitle="Work For Any Industry"
                    titleClass="title"
                    title="Boost Your Website's Visibility with Whatsapp Marketing Services"
                />
                <div className="all-services">
                    <SingleServiceBox
                        serviceIcon={img1}
                        Title="WhatsApp Campaign Strategy" 
                    />
                    <SingleServiceBox
                        serviceIcon={img2}
                        Title="WhatsApp Broadcast Messaging" 
                    />
                    <SingleServiceBox
                        serviceIcon={img3}
                        Title="WhatsApp Chatbots" 
                    />
                    <SingleServiceBox
                        serviceIcon={img4}
                        Title="WhatsApp Group Management" 
                    />
                    <SingleServiceBox
                        serviceIcon={img5}
                        Title="WhatsApp Customer Support" 
                    />
                    <SingleServiceBox
                        serviceIcon={img6}
                        Title="WhatsApp Newsletter Distribution" 
                    />
                    <SingleServiceBox
                        serviceIcon={img7}
                        Title="WhatsApp Analytics and Reporting" 
                    />
                    <SingleServiceBox
                        serviceIcon={img8}
                        Title="WhatsApp Surveys and Polls" 
                    />
                    <SingleServiceBox
                        serviceIcon={img9}
                        Title="WhatsApp Appointment Reminders" 
                    />
                    <SingleServiceBox
                        serviceIcon={img10}
                        Title="WhatsApp Event Marketing" 
                    />
                </div>
            </div>
        </div>
    );
}

export default Service;