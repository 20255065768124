import SectionTitle from '../../../../components/Common/SectionTitle';
import SingleServiceBox from '../../../../components/Service/SingleServiceBox';

// Service Icons
import img1 from '../../../../assets/img/technology/style8/1.png';
import img2 from '../../../../assets/img/technology/style8/2.png';
import img3 from '../../../../assets/img/technology/style8/3.png';
import img4 from '../../../../assets/img/technology/style8/4.png';
import img5 from '../../../../assets/img/technology/style8/5.png';
import img6 from '../../../../assets/img/technology/style8/6.png';
import img7 from '../../../../assets/img/technology/style8/7.png';
import img8 from '../../../../assets/img/technology/style8/8.png';
import img9 from '../../../../assets/img/technology/style8/9.png';
import img10 from '../../../../assets/img/technology/style8/10.png';

const Service = () => {

    return (
        <div className="rs-services style8 pt-120 pb-114 md-pt-70 md-pb-70"style={{  paddingtop: '40px !important' }}>
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text gold-color"
                    // subtitle="Work For Any Industry"
                    titleClass="title"
                    title="Boost Your Website's Visibility with Email Marketing Services"
                />
                <div className="all-services">
                    <SingleServiceBox
                        serviceIcon={img1}
                        Title="Email Campaign Strategy " 
                    />
                    <SingleServiceBox
                        serviceIcon={img2}
                        Title="Email Template Design" 
                    />
                    <SingleServiceBox
                        serviceIcon={img3}
                        Title="Email Content Creation" 
                    />
                    <SingleServiceBox
                        serviceIcon={img4}
                        Title="Deliverability Management" 
                    />
                    <SingleServiceBox
                        serviceIcon={img5}
                        Title="Personalization and Dynamic Content" 
                    />
                    <SingleServiceBox
                        serviceIcon={img6}
                        Title="A/B Testing and Optimization" 
                    />
                    <SingleServiceBox
                        serviceIcon={img7}
                        Title="Reporting and Analysis" 
                    />
                    <SingleServiceBox
                        serviceIcon={img8}
                        Title="Email Surveys and Feedback" 
                    />
                    <SingleServiceBox
                        serviceIcon={img9}
                        Title="Performance Tracking and Analytics" 
                    />
                    <SingleServiceBox
                        serviceIcon={img10}
                        Title="Email Retargeting" 
                    />
                    
                </div>
            </div>
        </div>
    );
}

export default Service;