import { Link } from 'react-router-dom';

import bgImg from '../../../../assets/img/bg/choose-bg.jpg';

const WhyChooseUs = () => {
    const bgStyle = {
        backgroundImage:`url(${bgImg})`
    }


    return (
        <div className="rs-why-choose style3 pt-120 pb-120 md-pt-74 md-pb-80" style={bgStyle}>
            <div className="container relative">
                <div className="sec">
                    <h1 className="title white-color">Why Should You Use Whatsapp marketing? Unlocking the Benefits.</h1>
                </div>
                <br></br>
                <div className="row align-items-center">
                    <div className="col-lg-12 md-pb-30">
                        <div className="features-content">
                            <ul className="features-list">
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>
                                    WhatsApp marketing allows direct and personalized communication with your audience, fostering a sense of connection and building stronger relationships.</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>WhatsApp messages have high open rates and engagement, ensuring your marketing messages reach and resonate with your target audience</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i><span>
                                    WhatsApp enables instant and convenient customer support, addressing queries, resolving issues, and building trust with prompt responses</span>
                                </li>
                                <li><i className="fa fa-check"></i>
                                <span>With over 2 billion active users, WhatsApp provides a massive reach, allowing you to connect with a global audience and expand your brand presence</span></li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>WhatsApp marketing is a cost-effective solution compared to traditional marketing channels, offering a cost-efficient way to reach and engage with customers</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i><span>
                                    Users who opt-in to receive your WhatsApp messages are already interested, resulting in a more receptive audience for your marketing efforts</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>WhatsApp supports various content formats like images, videos, audio messages, and documents, enabling rich media communication and captivating storytelling</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>The direct and personalized nature of WhatsApp marketing leads to higher conversion rates, driving customer actions and increasing sales</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span>WhatsApp allows you to segment your audience and send targeted campaigns, ensuring relevant and tailored messages for better response rates</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;