import React from 'react';
import Header from '../../../components/Layout/Header';
import Footer from '../../../components/Layout/Footer';
import SearchModal from '../../../components/Layout/Header/SearchModal';

import BannerDigitalMarketing from '../../../components/Banner/BannerDigitalMarketing';
import Brand from './BrandSection';
import SiteBreadcrumb from '../../../components/Common/Breadcumb';
import About from './AboutSection';
import ServiceFlip from './ServiceSectionFlip';
import Testimonial from './TestimonialSection';
import FaqMain from '../DigitalMarketing/Faq';
import ContactMain from './ContactMain';
// Breadcrumbs Image
import breadcrumbsImg from '../../../assets/img/breadcrumbs/6.jpg';


const DataCenter = () => {
    return (
        <React.Fragment>
            <Header
                parentMenu='service'
                activeMenu='/data-center'
            />

            {/* Banner-start */}
            <BannerDigitalMarketing />
            {/* Banner-end */}

            {/* About-area-start */}
			<About />
			{/* About-area-end */}

            <ServiceFlip />

            {/* Testimonial-start */}
            <Testimonial />
            {/* Testimonial-end */}

            {/* Faq */}
            <FaqMain />
            {/* Faq */}

            {/* ContactMain Section Start */}
            <ContactMain />
            {/* ContactMain Section End */}

            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>
    );
}

export default DataCenter;