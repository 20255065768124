import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import projectImg1 from '../../../assets/img/brandidentity/discountpostss1.png';
import projectImg2 from '../../../assets/img/brandidentity/discountpostss2.png';
import projectImg3 from '../../../assets/img/brandidentity/bc-3.png';
import projectImg4 from '../../../assets/img/brandidentity/l-1.png';
import projectImg5 from '../../../assets/img/brandidentity/l-2.jpg';
import projectImg6 from '../../../assets/img/brandidentity/l-3.jpg';
import projectImg7 from '../../../assets/img/brandidentity/l-4.jpg';
import projectImg8 from '../../../assets/img/brandidentity/mc-1.jpg';
import projectImg9 from '../../../assets/img/brandidentity/discountpostss3.png';
import projectImg10 from '../../../assets/img/brandidentity/mc-3.png';
import projectImg11 from '../../../assets/img/brandidentity/mc-4.jpg';



const PortfolioTab = () => {

    let tab1 = "All",
        tab2 = "Marketing Collateral",
        tab3 = "Logo",
        tab4 = "Business Cards"
    const tabStyle = 'tab-menu text-center';

    return (
        <div className="tab-part">
            <Tabs>
                <TabList className={tabStyle}>
                    <Tab><button>{tab1}</button></Tab>
                    <Tab><button>{tab2}</button></Tab>
                    <Tab><button>{tab3}</button></Tab>
                    <Tab><button>{tab4}</button></Tab>
                </TabList>

                <TabPanel>
                    <div id="portfolio-grid" className="row">
                        <div className="col-lg-4 col-md-6 md-mb-30">
                            <div className="project-item mb-30">
                                <div className="project-img">
                                    <img
                                        src={projectImg1}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Business Cards</Link></h3>
                                    </div>
                                </div>
                            </div>                         

                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg4}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Logo</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 sm-mb-30">
                            <div className="project-item mb-30">
                                <div className="project-img">
                                    <img
                                        src={projectImg2}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Business Cards</Link></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg5}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Logo</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="project-item mb-30">
                                <div className="project-img">
                                    <img
                                        src={projectImg3}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Business Cards</Link></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg6}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Logo</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="portfolio-grid" className="row">
                        <div className="col-lg-4 col-md-6 md-mb-30">
                            <div className="project-item mb-30">
                                <div className="project-img">
                                    <img
                                        src={projectImg7}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Logo</Link></h3>
                                    </div>
                                </div>
                            </div>                         

                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg8}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Marketing Collateral</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 sm-mb-30">
                            <div className="project-item mb-30">
                                <div className="project-img">
                                    <img
                                        src={projectImg9}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Marketing Collateral</Link></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg4}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Marketing Collateral</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="project-item mb-30">
                                <div className="project-img">
                                    <img
                                        src={projectImg11}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Marketing Collateral</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div id="portfolio-grid" className="row">
                       
                        <div className="col-lg-4 col-md-6 sm-mb-30">
                            <div className="project-item mb-30">
                                <div className="project-img">
                                    <img
                                        src={projectImg8}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Marketing Collateral</Link></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg9}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Marketing Collateral</Link></h3>
                                    </div>
                                </div>
                            </div>

                        </div>

                        
                        <div className="col-lg-4 col-md-6">
                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg10}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Marketing Collateral</Link></h3>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg11}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Marketing Collateral</Link></h3>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        
                        
                    </div>
                </TabPanel>
                <TabPanel>
                    <div id="portfolio-grid" className="row">
                        <div className="col-lg-4 col-md-6 md-mb-30">
                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg4}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Logo</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 md-mb-30">
                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg5}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Logo</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 md-mb-30">
                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg6}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Logo</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 sm-mb-30">
                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg7}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Logo</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div id="portfolio-grid" className="row">
                        <div className="col-lg-4 col-md-6 md-mb-30">
                            <div className="project-item mb-30">
                                <div className="project-img">
                                    <img
                                        src={projectImg1}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Business Cards</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 sm-mb-30">
                            <div className="project-item mb-30">
                                <div className="project-img">
                                    <img
                                        src={projectImg2}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Business Cards</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="project-item">
                                <div className="project-img">
                                    <img
                                        src={projectImg3}
                                        alt="images"
                                    />
                                </div>
                                <div className="project-content">
                                    <div className="project-inner">
                                        <h3 className="title"><Link to="#">Business Cards</Link></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </div>

    );
}

export default PortfolioTab;